import PropTypes from 'prop-types';
import React from 'react';
import { processGoalApproachQuestion } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const GoalApproachReadOnlyQuestion = ({ question }) => {
  const processedData = processGoalApproachQuestion(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  const { goalSelection, approachSelection } = processedData;

  return (
    <ReadOnlyQuestion question={question}>
      <p>Goal: {goalSelection}</p>
      <p>Approach: {approachSelection}</p>
    </ReadOnlyQuestion>
  );
};

GoalApproachReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default GoalApproachReadOnlyQuestion;
