/* eslint-disable no-underscore-dangle */
/* global STANDARD_DATE_FORMAT */
import cn from 'classnames';
import IPSReportViewer from 'components/advisor/ips/report/viewer';
import {
  ACCOUNT_PROPOSAL_TYPE,
  MODE_URL_PARAM,
  READ_ONLY_MODE
} from 'components/advisor/proposal/constants';
import ProposalReportViewer from 'components/advisor/proposal/report/viewer';
import { getRequiredSigners } from 'components/advisor/templates/sections/signature/digital-signature/utils';
import { FormGroup, VerboseErrorInput } from 'components/form';
import ReactDateRangePicker from 'components/form/date-range-picker';
import ManagementFeeField from 'components/form/management-fee-field';
import InlineTextEditor from 'components/inline-text-editor';
import TooltipV2 from 'components/tooltip-v2';
import CustomizeOrderModal from 'containers/advisor/templates/customize-order-modal';
import {
  IPS_DEFAULT_TEMPLATE,
  PROPOSAL_DEFAULT_TEMPLATE
} from 'containers/advisor/templates/defaults';
import ReportOptionsToggleable from 'containers/advisor/templates/report-options-toggleable';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import moment from 'moment';
import {
  ARCHIVED,
  DRAFT,
  PUBLISHED,
  hasReportReviews,
  isReportReadOnly
} from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { DateValidator, validation } from 'utils/form';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getReportUrl, getTargetInvestors, setPrecision } from 'utils/utils';
import MultiPortfolioSelector from '../../../portfolio-selector/multi';
import WeightedPortfolioSelector from '../../../portfolio-selector/weighted';
import {
  SECURITY_TYPE,
  getAsyncPortfolioSuggestionsDefault
} from '../../../portfolio-selector/weighted/utils';
import '../../common/form/styles.scss';
import { PROPOSAL_SELECT_STYLES } from '../../common/form/utils';
import {
  DEFAULT_STARTING_VALUE,
  MAX_START_DATE,
  MIN_START_DATE,
  convertPortfoliosAmountsToPercentages,
  getPortfolioSuggestionsDefault,
  handleStartingValueChange,
  handleYearlyWithdrawalAmountChange,
  handleYearlyWithdrawalRateChange,
  normalizeProposalPercentageValues
} from '../../utils';
import {
  ARCHIVED_NOTIFICATION_VERB,
  CREATED_NOTIFICATION_VERB,
  DELETED_NOTIFICATION_VERB,
  PUBLISHED_NOTIFICATION_VERB,
  REPORTS_BASE_URL_ENDS_WITH_ID,
  REPORTS_BASE_URL_REGEX,
  REPORTS_COPY_SUFFIX,
  RESTORED_NOTIFICATION_VERB,
  SAVED_NOTIFICATION_VERB,
  UPDATED_NOTIFICATION_VERB
} from './constants';
import ProposalFormDeleteModal from './delete-modal';
import ProposalFormPublishModal from './publish-modal';
import ProposalFormRenameModal from './rename-modal';
import ProposalSignatureRequestModal from './signature-request-modal';
import SignatureRequestTrailInline from './signature-request-trail/inline';

const validate = (values, { isIPS }) => {
  const errors = {};
  errors.target =
    errors.target || validation.required(values.target) || validation.empty(values.target);
  errors.template = errors.template || validation.required(values.template);

  errors.startingValue =
    errors.startingValue ||
    validation.required(values.startingValue) ||
    validation.floatPositive(values.startingValue) ||
    validation.nonZero(values.startingValue);

  errors.period =
    errors.period ||
    new DateValidator(values.period?.[0])
      .required()
      .valid()
      .min(MIN_START_DATE)
      .max(MAX_START_DATE)
      .monthDifference(values.period?.[1], 9)?.error;

  if (!isIPS) {
    errors.targetManagementFee =
      errors.targetManagementFee ||
      validation.required(values.targetManagementFee) ||
      validation.isFloatPercentage(values.targetManagementFee);

    errors.recommendedManagementFee =
      errors.recommendedManagementFee ||
      validation.required(values.recommendedManagementFee) ||
      validation.isFloatPercentage(values.recommendedManagementFee);

    errors.benchmarkManagementFee =
      errors.benchmarkManagementFee ||
      validation.required(values.benchmarkManagementFee) ||
      validation.isFloatPercentage(values.benchmarkManagementFee);

    errors.yearlyWithdrawalRate =
      errors.yearlyWithdrawalRate ||
      validation.required(values.yearlyWithdrawalRate) ||
      validation.isFloatPercentage(values.yearlyWithdrawalRate);

    errors.yearlyWithdrawalAmount =
      errors.yearlyWithdrawalAmount || validation.floatPositive(values.yearlyWithdrawalAmount);
  }

  return errors;
};

const reportNotification = (name, verb, celebration = true) =>
  toast.success(
    <div>
      {celebration && (
        <>
          <span role="img" aria-label="celebration">
            🎉
          </span>{' '}
          Great job!
        </>
      )}{' '}
      <span style={{ fontWeight: 'bold' }}>{name}</span> was {verb} successfully
    </div>
  );

export class ProposalForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      asyncSuggestions: [],
      benchmarkTotalValue: 100,
      creatingReport: false,
      householdOption: null,
      initializedForm: false,
      recommendedTotalValue: 100,
      requiresAsyncSuggestions: true,
      requiresFormInitialization: false,
      updatingReport: false,
      uploadingReport: false
    };
  }

  componentDidMount() {
    const { fields } = this.props;
    if (fields.recommended.initialValue.length === 0) this.setState({ recommendedTotalValue: 0 });
    this.setState({
      requiresFormInitialization: REPORTS_BASE_URL_ENDS_WITH_ID.test(window.location.pathname)
    });
  }

  componentDidUpdate() {
    const { accountProvider, marketProvider, modelProvider } = this.context;
    const { initializeForm, isIPS, proposal, targetSuggestions } = this.props;
    const {
      asyncSuggestions,
      initializedForm,
      requiresAsyncSuggestions,
      requiresFormInitialization
    } = this.state;

    if (requiresAsyncSuggestions && proposal && !asyncSuggestions.length) {
      this.setState({ requiresAsyncSuggestions: false });
      getAsyncPortfolioSuggestionsDefault(
        proposal,
        accountProvider,
        marketProvider,
        modelProvider
      ).then(suggestions => {
        this.setState({ asyncSuggestions: suggestions.flat() });
      });
    }

    if (
      !initializedForm &&
      requiresFormInitialization &&
      proposal &&
      !!targetSuggestions?.length &&
      !!asyncSuggestions?.length
    ) {
      this.setState({ initializedForm: true });

      const { suggestionsDefault: target } = getPortfolioSuggestionsDefault(
        proposal,
        targetSuggestions,
        'target'
      );

      let recommended;
      if (proposal.recommended) {
        const {
          totalValue: recommendedTotalValue,
          suggestionsDefault: recommendedSuggestionsDefault
        } = getPortfolioSuggestionsDefault(proposal, asyncSuggestions, 'recommended');
        this.setState({ recommendedTotalValue });
        recommended = recommendedSuggestionsDefault;
      }

      let benchmark;
      if (proposal.benchmark) {
        const { totalValue: benchmarkTotalValue, suggestionsDefault: benchmarkSuggestionsDefault } =
          getPortfolioSuggestionsDefault(proposal, asyncSuggestions, 'benchmark');
        this.setState({ benchmarkTotalValue });
        benchmark = benchmarkSuggestionsDefault;
      }

      const values = {
        period: [proposal.start, proposal.end],
        recommended,
        recommendedLabel: proposal.recommended_label,
        recommendedWithPercentages: proposal.recommended_with_percentages,
        startingValue: proposal.starting_value,
        target,
        targetLabel: proposal.target_label,
        template: proposal.template.id
      };

      if (!isIPS) {
        values.benchmark = benchmark;
        values.benchmarkLabel = proposal.benchmark_label;
        values.benchmarkManagementFee = String(proposal.benchmark_management_fee * 100);
        values.benchmarkWithPercentages = proposal.benchmark_with_percentages;
        values.recommendedManagementFee = String(proposal.recommended_management_fee * 100);
        values.targetManagementFee = String(proposal.target_management_fee * 100);
        values.yearlyWithdrawalAmount = String(
          proposal.starting_value * proposal.yearly_withdrawal_rate
        );
        values.yearlyWithdrawalRate = String(proposal.yearly_withdrawal_rate * 100);
      }

      initializeForm(values);
    }
  }

  handleBenchmarkChange = portfolios => {
    const { fields } = this.props;
    if (!portfolios) portfolios = [];
    const benchmarkTotalValue = setPrecision(
      portfolios.reduce((acum, b) => acum + b.weight, 0),
      2
    );
    this.setState({ benchmarkTotalValue });
    fields.benchmark.onChange(portfolios);
  };

  handleRecommendedChange = portfolios => {
    const { fields } = this.props;
    if (!portfolios) portfolios = [];
    const recommendedTotalValue = setPrecision(
      portfolios.reduce((acum, b) => acum + b.weight, 0),
      2
    );
    this.setState({ recommendedTotalValue });
    fields.recommended.onChange(portfolios);
  };

  handleTargetChange = (portfolios, actionMeta) => {
    const { fields, onTargetChange, targetSuggestions } = this.props;
    const { householdOption } = this.state;
    if (!portfolios) portfolios = [];
    const targetValue = portfolios.reduce((acum, b) => acum + b.amount, 0);

    /* Evaluate the Entire household option */
    if (actionMeta.option && actionMeta.option.isFullHousehold) {
      const currentValues = [...portfolios];

      const newOptions = targetSuggestions.filter(element => element.label !== 'HOUSEHOLD');
      let houseHoldOption = targetSuggestions.find(element => element.label === 'HOUSEHOLD');
      if (houseHoldOption) {
        houseHoldOption = { ...houseHoldOption, options: actionMeta.option.suggestions }; // Overwrite household option

        const currentHouseHold = targetSuggestions.find(element => element.label === 'HOUSEHOLD'); // Save temporal household
        this.setState({ householdOption: currentHouseHold }); // Save temporal household

        const newValues = currentValues.filter(element => !element.isFullHousehold);
        const newSelectedTarget = [...newValues, ...actionMeta.option.suggestions]; // New selected items
        fields.target.onChange(newSelectedTarget);
        if (onTargetChange) onTargetChange(newSelectedTarget, [...newOptions, houseHoldOption]);
      }
    } else {
      let currentOptions = null;
      /* Condition only to remove account that belongs to household */
      if (actionMeta.action === 'remove-value' && actionMeta.removedValue.belongToHouseHoldGroup) {
        const currentHouseHoldAccounts = portfolios
          ? portfolios.filter(element => element.belongToHouseHoldGroup)
          : [];
        /* Evaluate if the selected items don't have household accounts */
        if (currentHouseHoldAccounts.length === 0) {
          const newOptions = targetSuggestions.filter(element => element.label !== 'HOUSEHOLD');
          currentOptions = [...newOptions, householdOption]; // Set temporal household
        }
      }

      fields.target.onChange(portfolios);
      if (onTargetChange) onTargetChange(portfolios, currentOptions);
    }

    // The value of startingValue is being normalized (see src/app/reducers/form.js)
    // in consequence, its initialValue is dirty (not pristine).
    // We need to check if it has been visited/tocuhed before overriding the value.
    if (
      fields.startingValue.pristine ||
      !fields.startingValue.visited ||
      fields.startingValue.autofilled
    )
      fields.startingValue.autofill(targetValue || DEFAULT_STARTING_VALUE);
  };

  handleReportCreation = verb => name => {
    this.setState({ creatingReport: true });

    const { ipsProvider, proposalProvider, routerActions } = this.context;
    const {
      currentReport,
      currentReportSettings,
      entityId,
      investor,
      isIPS,
      reportUrl,
      proposal,
      ips
    } = this.props;
    const provider = isIPS ? ipsProvider : proposalProvider;
    const data = { name, [isIPS ? 'ips' : 'proposal']: entityId, settings: currentReportSettings };

    const query = new URLSearchParams(window.location.search);
    const isReadOnly =
      query.get(MODE_URL_PARAM) === READ_ONLY_MODE || currentReport?.status === PUBLISHED;

    const amplitudeDict = {
      saved: isIPS ? 'ips.saved' : 'proposal.saved',
      created: isIPS ? 'ips.duplicated' : 'proposal.duplicated'
    };

    const dataEntity = isIPS ? ips : proposal;
    const amplitudePost = {
      id: dataEntity.id,
      investor: investor.id,
      is_prospect: investor.is_prospect
    };

    if (isIPS) amplitudePost.with_recommended = !!dataEntity.proposal.recommended;
    if (!isIPS) {
      amplitudePost.with_recommended = !!dataEntity.recommended;
      amplitudePost.with_benchmark = !!dataEntity.benchmark;
    }

    trackAmplitudeEvent(amplitudeDict[verb], amplitudePost);

    return provider
      .createReport(data, investor.id, investor.is_prospect)
      .then(({ data: report }) => {
        reportNotification(report.name, verb);
        this.setState({ uploadingReport: true });
        provider
          .uploadPdfReport(
            isReadOnly ? getReportUrl(currentReport) : reportUrl,
            report.id,
            investor.id,
            investor.is_prospect
          )
          .finally(() => {
            this.setState({ uploadingReport: false });
          });
        const url = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, `/${report.id}`);
        routerActions.push(url);
      })
      .finally(() => {
        this.setState({ creatingReport: false });
      });
  };

  handleReportUpdate = (data, verb = null, celebration = true) => {
    this.setState({ updatingReport: true });

    const { ipsProvider, proposalProvider } = this.context;
    const { currentReport, investor, isIPS } = this.props;
    const provider = isIPS ? ipsProvider : proposalProvider;

    if (currentReport)
      provider
        .updateReport(data, currentReport.id, investor.id, investor.is_prospect)
        .then(({ data: report }) => {
          if (verb) reportNotification(report.name, verb, celebration);
          provider.setCurrentReportUnsaved(false);
        })
        .finally(() => {
          this.setState({ updatingReport: false });
        });
  };

  handleReportDelete = async () => {
    const { ipsProvider, proposalProvider, routerActions } = this.context;
    const { currentReport, investor, isIPS, proposal, ips } = this.props;

    const reportsUrl = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, '');
    const provider = isIPS ? ipsProvider : proposalProvider;

    if (currentReport) {
      const { name: reportName } = currentReport;

      const amplitudEvent = isIPS ? 'ips.deleted' : 'proposal.deleted';
      const data = isIPS ? ips : proposal;

      const amplitudePost = {
        id: data.id,
        investor: data.id,
        is_prospect: data.is_prospect,
        with_recommended: !!data.recommended
      };

      if (!isIPS) amplitudePost.with_benchmark = !!data.benchmark;
      trackAmplitudeEvent(amplitudEvent, amplitudePost);

      await provider.deleteReport(currentReport.id, investor.id, investor.is_prospect).then(() => {
        reportNotification(reportName, DELETED_NOTIFICATION_VERB, false);
        routerActions.push(reportsUrl);
      });
    }
  };

  handleSaveTemplateContent = async content => {
    const { ipsProvider, proposalProvider } = this.context;
    const { currentReport, isIPS, proposal } = this.props;

    const provider = isIPS ? ipsProvider : proposalProvider;

    return provider.update(proposal.id, { template_content: content }).then(() => {
      if (currentReport) provider.setCurrentReportUnsaved(true);
    });
  };

  handleSaveTemplate = async (_, content) => this.handleSaveTemplateContent(content);

  handleSaveTemplateOrder = async (content, _) => this.handleSaveTemplateContent(content);

  handleEditReport = () => {
    const { routerActions } = this.context;
    const editReportUrl = new URL(window.location.pathname, window.location.origin);
    routerActions.push(editReportUrl);
  };

  handleReportNameChange = name => {
    this.handleReportUpdate({ name });
  };

  handleReportReferenceChange = () => {
    const { ipsProvider, proposalProvider } = this.context;
    const { currentReport, currentReportSettings, entityId, investor, isIPS, reportUrl } =
      this.props;
    const provider = isIPS ? ipsProvider : proposalProvider;

    this.handleReportUpdate(
      { [isIPS ? 'ips' : 'proposal']: entityId, settings: currentReportSettings },
      UPDATED_NOTIFICATION_VERB
    );
    this.setState({ uploadingReport: true });
    provider
      .uploadPdfReport(reportUrl, currentReport.id, investor.id, investor.is_prospect)
      .finally(() => {
        this.setState({ uploadingReport: false });
      });
  };

  handleReportStatusChange = status => () => {
    const { currentReport, entityId: id, investor, isIPS } = this.props;

    const notificationVerb = {
      [ARCHIVED]: ARCHIVED_NOTIFICATION_VERB,
      [DRAFT]: RESTORED_NOTIFICATION_VERB,
      [PUBLISHED]: PUBLISHED_NOTIFICATION_VERB
    };
    const source = isIPS ? 'ips' : 'proposal';

    const amplitudeDict = {
      [ARCHIVED]: `${source}.archived`,
      [DRAFT]: `${source}.restored`,
      [PUBLISHED]: `${source}.published`
    };

    const amplitudePost = {
      id,
      investor: investor.id,
      is_prospect: investor.is_prospect,
      with_recommended: !!currentReport.recommended
    };
    if (!isIPS) amplitudePost.with_benchmark = !!currentReport.benchmark;

    trackAmplitudeEvent(amplitudeDict[status], amplitudePost);

    this.handleReportUpdate({ status }, notificationVerb[status], status !== ARCHIVED);
  };

  onSubmit = async values => {
    const {
      currentReport,
      fields: { benchmarkWithPercentages, recommendedWithPercentages },
      isIPS,
      onGenerate
    } = this.props;

    // avoids submitting and creating a new proposal when it's already in read-only
    // mode and requesting the client signatures
    if (isReportReadOnly(currentReport?.status)) return null;

    const { benchmarkTotalValue, recommendedTotalValue } = this.state;

    normalizeProposalPercentageValues(values);

    if (!recommendedWithPercentages.value) {
      values.recommended_total_value = recommendedTotalValue;
      values.recommended = convertPortfoliosAmountsToPercentages(values.recommended);
    }
    if (!benchmarkWithPercentages.value && !isIPS) {
      values.benchmark_total_value = benchmarkTotalValue;
      values.benchmark = convertPortfoliosAmountsToPercentages(values.benchmark);
    }

    return onGenerate(values);
  };

  reportType = lower => {
    const { isIPS } = this.props;
    if (isIPS) return 'IPS';
    return lower ? 'proposal' : 'Proposal';
  };

  toggleRecommendedUnit = () => {
    const {
      fields: { recommendedWithPercentages }
    } = this.props;
    recommendedWithPercentages.onChange(!recommendedWithPercentages.value);
  };

  toggleBenchmarkUnit = () => {
    const {
      fields: { benchmarkWithPercentages }
    } = this.props;
    benchmarkWithPercentages.onChange(!benchmarkWithPercentages.value);
  };

  render() {
    const { authProvider, user } = this.context;

    const {
      allowPrint,
      currentReport,
      currentReportSettings,
      entityId,
      error,
      fields: {
        benchmark,
        benchmarkLabel,
        benchmarkManagementFee,
        benchmarkWithPercentages,
        period,
        recommended,
        recommendedLabel,
        recommendedManagementFee,
        recommendedWithPercentages,
        startingValue,
        target,
        targetLabel,
        targetManagementFee,
        template,
        yearlyWithdrawalAmount,
        yearlyWithdrawalRate
      },
      handleSubmit,
      invalid,
      investor,
      isIPS,
      loading,
      managers,
      proposal,
      proposalType,
      reportUrl,
      scope,
      submitting,
      targetSuggestions,
      templateOptions,
      templateSuggestions
    } = this.props;

    const {
      initializedForm,
      benchmarkTotalValue,
      creatingReport,
      requiresFormInitialization,
      recommendedTotalValue,
      updatingReport,
      uploadingReport
    } = this.state;

    const investors = proposal ? getTargetInvestors(proposal) : [];
    const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
    const isDraftReport = window.location.pathname.includes('new');
    const isInvalidTemplate = templateOptions.find(el => !el.approved && el.id === template.value);
    const portfoliosTotalAmount = (target.value || []).reduce((acum, t) => acum + t.amount, 0);
    const reportActionDisabled = updatingReport || creatingReport || submitting || !allowPrint;
    const reportsUrl = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, '');
    const templateContent =
      proposal?.template_content ||
      proposal?.template?.content ||
      (isIPS ? IPS_DEFAULT_TEMPLATE : PROPOSAL_DEFAULT_TEMPLATE);
    const unchangedReport =
      (currentReport?.proposal === entityId || currentReport?.ips === entityId) &&
      !currentReport?.unsaved;

    const requiredSigners = getRequiredSigners(templateContent, managers, investors);
    const hasRequestSignaturePermissions =
      user?.advisor?.company?.digital_signature_enabled && !_.isEmpty(requiredSigners);

    const canOrderTemplate =
      proposal?.id && (isComplianceOrAbove || proposal?.template?.settings?.allowSectionsOrder);
    const query = new URLSearchParams(window.location.search);
    const isReadOnly =
      query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);

    return (
      <>
        <div className="proposal-form-breadcrumbs">
          <Link to={reportsUrl}>
            {isIPS ? 'IPS' : 'Proposals'} for {investor.full_name}
          </Link>{' '}
          &gt;{' '}
          {isDraftReport ? (
            'Draft'
          ) : (
            <InlineTextEditor
              onChange={this.handleReportNameChange}
              text={currentReport?.name}
              updating={updatingReport}
            />
          )}
          {currentReport && (
            <div className={cn('report-badge', `report-badge--${currentReport.status}`)}>
              {currentReport.status.replace(/_/g, ' ')}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off" className="proposal-form">
          {!isReadOnly && (
            <div
              className={cn('steps', {
                'less-opacity':
                  loading || isReadOnly || (requiresFormInitialization && !initializedForm)
              })}
            >
              <div className="step">
                <div className="step__container step__container--target" data-ips={isIPS}>
                  {proposalType !== ACCOUNT_PROPOSAL_TYPE && (
                    <MultiPortfolioSelector
                      suggestions={targetSuggestions}
                      defaultValue={target.value}
                      onChange={this.handleTargetChange}
                    />
                  )}

                  <div>
                    {!isIPS && <ManagementFeeField field={targetManagementFee} />}
                    <div className="recommended-label">
                      <label htmlFor="target-label">Customize label (optional)</label>
                      <VerboseErrorInput
                        name="target-label"
                        type="text"
                        {...targetLabel}
                        placeholder="Target"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="step">
                <div className="step__container" data-ips={isIPS}>
                  <div className="recommended">
                    <div className="recommended__label">
                      <label htmlFor="recommended">Select a matching model (optional)</label>
                      <Toggle
                        className="toggle-unit"
                        icons={{
                          checked: <span className="toggle-unit__icon">%</span>,
                          unchecked: <span className="toggle-unit__icon">$</span>
                        }}
                        id="toggle-recommended-unit"
                        onChange={this.toggleRecommendedUnit}
                        checked={!!recommendedWithPercentages.value}
                      />
                    </div>
                    <WeightedPortfolioSelector
                      defaultValues={recommended.value}
                      key="recommended-weighted-portfolio"
                      onChange={this.handleRecommendedChange}
                      portfoliosTotalAmount={portfoliosTotalAmount}
                      scope={scope}
                      withPercentages={!!recommendedWithPercentages.value}
                    />
                  </div>

                  <div>
                    {!isIPS && <ManagementFeeField field={recommendedManagementFee} />}
                    <div className="recommended-label">
                      <label htmlFor="recommended-label">Customize label (optional)</label>
                      <VerboseErrorInput
                        name="model-label"
                        type="text"
                        {...recommendedLabel}
                        placeholder="Model"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!isIPS && (
                <div className="step">
                  <div className="step__container" data-ips={isIPS}>
                    <div className="benchmark">
                      <div className="benchmark__label">
                        <label htmlFor="benchmark">Select a benchmark (optional)</label>
                        <Toggle
                          className="toggle-unit"
                          icons={{
                            checked: <span className="toggle-unit__icon">%</span>,
                            unchecked: <span className="toggle-unit__icon">$</span>
                          }}
                          id="toggle-benchmark-unit"
                          onChange={this.toggleBenchmarkUnit}
                          checked={!!benchmarkWithPercentages.value}
                        />
                      </div>
                      <WeightedPortfolioSelector
                        defaultValues={benchmark.initialValue}
                        key="benchmark-weighted-portfolio"
                        onChange={this.handleBenchmarkChange}
                        scope={scope}
                        type={SECURITY_TYPE}
                        withPercentages={!!benchmarkWithPercentages.value}
                      />
                    </div>

                    <div>
                      <ManagementFeeField field={benchmarkManagementFee} />
                      <div className="benchmark-label">
                        <label htmlFor="benchmark-label">Customize label (optional)</label>
                        <VerboseErrorInput
                          name="benchmark-label"
                          type="text"
                          {...benchmarkLabel}
                          placeholder="Benchmark"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="step">
                <div className="step__last-container" data-ips={isIPS}>
                  <div className="template">
                    <label htmlFor="template">Template *</label>
                    <Select
                      className="options"
                      options={templateSuggestions}
                      onChange={template.onChange}
                      value={templateOptions.find(t => t.value === template.value)}
                      styles={PROPOSAL_SELECT_STYLES}
                    />
                    {!!(template.touched && template.error) && (
                      <div className="text-danger" style={{ marginTop: 10, fontSize: 12 }}>
                        {template.error}
                      </div>
                    )}
                  </div>
                  {!isIPS && (
                    <>
                      <FormGroup {...startingValue} className="starting-value form-group">
                        <VerboseErrorInput
                          {...startingValue}
                          className="form-control"
                          fieldsetClassName="form-group-money"
                          label="Starting value *"
                          placeholder="Starting value"
                          type="text"
                          onChange={handleStartingValueChange(
                            startingValue,
                            yearlyWithdrawalAmount,
                            yearlyWithdrawalRate
                          )}
                        />
                      </FormGroup>

                      <FormGroup {...period} className="start form-group">
                        <VerboseErrorInput {...period} label="Period *">
                          <ReactDateRangePicker
                            className="form-control"
                            onChange={period.onChange}
                            value={period.value}
                            error={period.error}
                            feedbackMessage="Minimum 9 months required"
                          />
                        </VerboseErrorInput>
                      </FormGroup>

                      <FormGroup {...yearlyWithdrawalRate} className="yearly-withdrawal form-group">
                        <label htmlFor="yearly-withdrawal">Yearly Withdrawal *</label>
                        <div>
                          <VerboseErrorInput
                            {...yearlyWithdrawalRate}
                            className="form-control yearly-withdrawal__percent"
                            fieldsetClassName="form-group-percentage"
                            type="text"
                            onChange={handleYearlyWithdrawalRateChange(
                              startingValue,
                              yearlyWithdrawalAmount,
                              yearlyWithdrawalRate
                            )}
                          />
                          <VerboseErrorInput
                            {...yearlyWithdrawalAmount}
                            className="form-control yearly-withdrawal__amount"
                            fieldsetClassName="form-group-money"
                            type="text"
                            onChange={handleYearlyWithdrawalAmountChange(
                              startingValue,
                              yearlyWithdrawalAmount,
                              yearlyWithdrawalRate
                            )}
                          />
                        </div>
                      </FormGroup>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {!isReadOnly && error && <p className="text-danger">{error}</p>}

          {!isReadOnly && !submitting && proposal && (
            <ReportOptionsToggleable
              canOrder={canOrderTemplate}
              canUpdate
              handleSaveTemplate={this.handleSaveTemplate}
              handleSaveTemplateOrder={this.handleSaveTemplateOrder}
              templateContent={templateContent}
            />
          )}

          <div className="form-actions">
            <div className="form-actions__generation">
              {!isReadOnly && !submitting && (
                <button
                  type="submit"
                  disabled={
                    isReadOnly ||
                    submitting ||
                    loading ||
                    invalid ||
                    (requiresFormInitialization && !initializedForm) ||
                    (recommendedWithPercentages.value &&
                      recommendedTotalValue !== 0 &&
                      recommendedTotalValue !== 100) ||
                    (recommendedTotalValue === 0 && !_.isEmpty(recommended.value)) ||
                    (benchmarkWithPercentages.value &&
                      benchmarkTotalValue !== 0 &&
                      benchmarkTotalValue !== 100) ||
                    (benchmarkTotalValue === 0 && !_.isEmpty(benchmark.value))
                  }
                  className="btn btn-primary"
                >
                  Generate
                </button>
              )}

              {isReadOnly && !hasReportReviews(currentReport?.status) && (
                <TooltipV2
                  place="bottom"
                  id="edit-report-tooltip"
                  label={
                    isReportReadOnly(currentReport?.status)
                      ? `This ${this.reportType(true)} is published. It cannot be edited`
                      : `Continue editing this ${this.reportType(true)}`
                  }
                >
                  <div data-for="edit-report-tooltip" data-tip="">
                    <button
                      type="button"
                      onClick={this.handleEditReport}
                      className="btn btn-primary"
                      disabled={isReportReadOnly(currentReport?.status)}
                    >
                      Continue Editing
                    </button>
                  </div>
                </TooltipV2>
              )}

              {user?.advisor?.company?.digital_signature_enabled &&
                hasReportReviews(currentReport?.status) && (
                  <SignatureRequestTrailInline reviews={currentReport.reviews} />
                )}

              {!isReadOnly && isIPS && !submitting && allowPrint && (
                <IPSReportViewer
                  directDownload={isReadOnly}
                  isInvalidTemplate={isInvalidTemplate}
                  subtitle={investor.full_name}
                />
              )}

              {!isReadOnly && !isIPS && !submitting && (
                <ProposalReportViewer
                  directDownload={isReadOnly}
                  isInvalidTemplate={isInvalidTemplate}
                  subtitle={investor.full_name}
                />
              )}
            </div>

            <div className="form-actions__saving">
              {isDraftReport && entityId && (
                <ProposalFormRenameModal
                  buttonClassName="btn-primary"
                  buttonDisabled={reportActionDisabled || invalid}
                  buttonText={creatingReport ? 'Saving...' : 'Save'}
                  defaultName={`${this.reportType()} for ${investor.full_name} - ${moment().format(
                    STANDARD_DATE_FORMAT
                  )}`}
                  isIPS={isIPS}
                  isReadOnly={isReadOnly}
                  process={this.handleReportCreation(SAVED_NOTIFICATION_VERB)}
                  reportOrientation={currentReportSettings.orientation}
                  reportType={this.reportType(true)}
                  reportUrl={isReadOnly ? getReportUrl(currentReport) : reportUrl}
                  tooltipId="save-report"
                />
              )}

              {!isDraftReport && entityId && (
                <>
                  {currentReport?.status === DRAFT && (
                    <ProposalFormPublishModal
                      buttonDisabled={uploadingReport}
                      label={currentReport?.name}
                      onPublish={this.handleReportStatusChange(PUBLISHED)}
                      reportType={this.reportType(true)}
                      title={`Publish ${this.reportType()}?`}
                      tooltipText={`Publish the current ${this.reportType(true)}`}
                    />
                  )}

                  {(currentReport?.status === DRAFT || currentReport?.status === PUBLISHED) &&
                    hasRequestSignaturePermissions && (
                      <ProposalSignatureRequestModal
                        buttonDisabled={uploadingReport}
                        isIPS={isIPS}
                        reportId={currentReport.id}
                        reportInvestor={investor}
                        reportStatus={currentReport.status}
                        requiredSigners={requiredSigners}
                        title={`Request ${this.reportType()} Review & Signature`}
                      />
                    )}

                  {!isReadOnly && currentReport?.status === DRAFT && (
                    <TooltipV2
                      place="bottom"
                      id="update-report-tooltip"
                      label={
                        unchangedReport
                          ? 'There are no changes to save'
                          : `Update the ${this.reportType(true)} report with the latest changes`
                      }
                    >
                      <div data-for="update-report-tooltip" data-tip="">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={unchangedReport || reportActionDisabled || invalid}
                          onClick={this.handleReportReferenceChange}
                        >
                          {updatingReport ? 'Saving...' : 'Save'}
                        </button>
                      </div>
                    </TooltipV2>
                  )}

                  <ProposalFormRenameModal
                    buttonClassName="btn-outline-primary"
                    buttonDisabled={reportActionDisabled}
                    buttonText={updatingReport ? 'Saving as...' : 'Save as'}
                    defaultName={`${currentReport?.name} ${REPORTS_COPY_SUFFIX}`}
                    isIPS={isIPS}
                    isReadOnly={isReadOnly}
                    process={this.handleReportCreation(CREATED_NOTIFICATION_VERB)}
                    reportOrientation={currentReportSettings.orientation}
                    reportType={this.reportType(true)}
                    reportUrl={isReadOnly ? getReportUrl(currentReport) : reportUrl}
                    tooltipId="save-as-new-report"
                    tooltipText={`Create a copy of the current ${this.reportType(true)} report`}
                  />

                  {currentReport?.status === DRAFT && (
                    <TooltipV2
                      place="bottom"
                      id="archive-report-tooltip"
                      label={`Archive the current ${this.reportType(true)} report`}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        disabled={reportActionDisabled}
                        onClick={this.handleReportStatusChange(ARCHIVED)}
                        data-for="archive-report-tooltip"
                        data-tip=""
                      >
                        Archive
                      </button>
                    </TooltipV2>
                  )}

                  {currentReport?.status === ARCHIVED && (
                    <TooltipV2
                      place="bottom"
                      id="restore-report-tooltip"
                      label={`Restore the current ${this.reportType(true)} report`}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        disabled={reportActionDisabled}
                        onClick={this.handleReportStatusChange(DRAFT)}
                        data-for="restore-report-tooltip"
                        data-tip=""
                      >
                        Restore
                      </button>
                    </TooltipV2>
                  )}

                  {(currentReport?.status === DRAFT || currentReport?.status === ARCHIVED) && (
                    <ProposalFormDeleteModal
                      label={currentReport?.name}
                      onDelete={this.handleReportDelete}
                      title={isIPS ? 'Delete IPS?' : 'Delete Proposal?'}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {!isReadOnly && allowPrint && isInvalidTemplate && (
            <p style={{ textAlign: 'right', fontSize: '0.8rem' }}>
              To generate a PDF Report the selected template must be approved by an administrative
              role first
            </p>
          )}
        </form>
      </>
    );
  }
}

ProposalForm.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  ipsProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  proposalProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

ProposalForm.defaultProps = {
  allowPrint: false,
  currentReport: null,
  entityId: null,
  error: null,
  fields: {},
  investor: {},
  onGenerate: () => {},
  proposal: null,
  reportUrl: null,
  templateOptions: [],
  templateSuggestions: []
};

ProposalForm.propTypes = {
  allowPrint: PropTypes.bool,
  currentReport: PropTypes.object,
  currentReportSettings: PropTypes.object.isRequired,
  entityId: PropTypes.number,
  error: PropTypes.string,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  investor: PropTypes.object,
  ips: PropTypes.object.isRequired,
  isIPS: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  onGenerate: PropTypes.func,
  onTargetChange: PropTypes.func.isRequired,
  proposal: PropTypes.object,
  proposalType: PropTypes.string.isRequired,
  reportUrl: PropTypes.string,
  scope: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  targetSuggestions: PropTypes.array.isRequired,
  templateOptions: PropTypes.array,
  templateSuggestions: PropTypes.array
};

export default compose(
  connect((state, props) => ({
    currentReport: props.isIPS ? state.ips.currentReport : state.proposals.currentReport,
    currentReportSettings: props.isIPS
      ? state.ips.currentReportSettings
      : state.proposals.currentReportSettings,
    form: props.isIPS ? 'generateIPS' : 'generateProposal',
    ips: state.ips.view,
    proposal: props.isIPS ? state.ips.view?.proposal : state.proposals.view,
    reportUrl: props.isIPS ? state.ips.url : state.proposals.url
  })),
  reduxForm({
    touchOnChange: true,
    fields: [
      'benchmark',
      'benchmarkLabel',
      'benchmarkManagementFee',
      'benchmarkWithPercentages',
      'period',
      'recommended',
      'recommendedLabel',
      'recommendedManagementFee',
      'recommendedWithPercentages',
      'startingValue',
      'target',
      'targetLabel',
      'targetManagementFee',
      'template',
      'yearlyWithdrawalAmount',
      'yearlyWithdrawalRate'
    ],
    validate,
    overwriteOnInitialValuesChange: false
  })
)(BackendValidation(ProposalForm));
