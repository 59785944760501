import { processGoalApproachQuestion } from '../utils';

/**
 * Formats the goal and approach question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
export const formatGoalApproachQuestion = question => {
  const processedData = processGoalApproachQuestion(question);
  if (!processedData) return null;

  const { goalSelection, approachSelection } = processedData;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: `Goal: ${goalSelection}`,
    answer_2: `Approach: ${approachSelection}`
  };
};
