import { NEW_FEATURED_SECTIONS } from 'containers/advisor/new-tag';
import humps from 'humps';
import PropTypes from 'prop-types';
import React from 'react';

export const INVESTMENT_POLICY_REVIEW = '_investmentPolicyReview';

export const FOOTER_SEPARATOR = '&nbsp;';
export const SEPARATOR = '<hr />';

const getSectionTitle = ({ isCustomSection, isTemplateView, propertyKey, sourceTitle }) => {
  const title =
    sourceTitle ||
    (isTemplateView && !isCustomSection
      ? `(${humps
          .decamelize(propertyKey, { separator: ' ' })
          .replace(/\b\w/g, char => char.toUpperCase())})`
      : '');

  if (!title) return '';

  const newSectionBadge = NEW_FEATURED_SECTIONS.includes(propertyKey)
    ? ' <div class="new-tag">NEW</div>'
    : '';

  return `<h2${
    isTemplateView && !sourceTitle ? ' class="no-title"' : ''
  }>${title}${newSectionBadge}</h2>`;
};

export const getSectionBody = ({
  body,
  isCustomSection,
  isTemplateView,
  preBody,
  propertyKey,
  title: sourceTitle
}) => {
  const title = getSectionTitle({ isCustomSection, isTemplateView, propertyKey, sourceTitle });

  if (!body && !preBody && !title) return null;

  let content = body || '';
  content = preBody ? preBody + content : content;
  return title ? title + content : content;
};

export const readOnlyContent = x => {
  if (x) return x.split(SEPARATOR)[0];
  return '';
};

export const PageBreak = ({ text }) => (
  <div className="page-break">
    <span>{text || 'Page Break'}</span>
  </div>
);

PageBreak.propTypes = {
  text: PropTypes.string
};

PageBreak.defaultProps = {
  text: ''
};
