import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import ScrollCardsIndex from 'components/advisor/scroll-cards-index';
import { INVESTOR_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import BreakdownCustomSecuritiesToggleTitle from 'components/form/breakdown-custom-securities-toggle-title';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import {
  ALLOCATIONS_TYPE,
  INVESTMENT_STYLE_TYPE,
  SECTOR_EXPOSURE_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import {
  combinedPortfoliosSelector,
  totalAssetsSelector
} from 'components/advisor/investors/details/prism-investor/selectors';
import './styles.scss';

const InvestorPortfolioInsights = ({
  combinedPortfolios,
  breakdownCustomSecurities,
  investor,
  positions,
  totalAssets
}) => {
  const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[investor.id] || [];
  const prismScore = investor && investor.aggregated_prism_scores;

  return (
    <div className="investor-portfolio-analysis">
      {!_.isEmpty(positions) && !!totalAssets && (
        <ScrollCardsIndex score={prismScore && prismScore.overall} type={INVESTOR_TARGET_TYPE} />
      )}

      {/* Assets Allocation Section */}
      <div className="client-overview-risk-factor-analysis" id="allocation">
        <BreakdownCustomSecuritiesToggleTitle
          portfolio={{ id: investor.id, ...combinedPortfolios }}
          portfolioType={investor.is_prospect ? 'prospects' : 'investors'}
          sectionType={ALLOCATIONS_TYPE}
          title="Assets Allocation"
        />
        <div className="overview-client">
          <PositionsAnalysis
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              ALLOCATIONS_TYPE
            )}
            className="fill-space"
            hiddenVal={false}
            id={PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}
            portfolio={combinedPortfolios}
          />
        </div>
        <ScrollToTop />
      </div>

      {/* Investment Style Section */}
      <div className="client-overview-risk-factor-analysis box-position-analysis">
        <div className="header">
          <BreakdownCustomSecuritiesToggleTitle
            isNewFeature
            portfolio={{ id: investor.id, ...combinedPortfolios }}
            portfolioType={investor.is_prospect ? 'prospects' : 'investors'}
            sectionType={INVESTMENT_STYLE_TYPE}
          />
        </div>
        <div className="investment-style-container">
          <InvestmentStyle
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              INVESTMENT_STYLE_TYPE
            )}
            portfolio={combinedPortfolios}
          />
        </div>
        <ScrollToTop />
      </div>

      {/* Sector Exposure Section */}
      <div className="client-overview-risk-factor-analysis box-position-analysis">
        <div className="header" id="sector-exposure--header">
          <BreakdownCustomSecuritiesToggleTitle
            isNewFeature
            portfolio={{ id: investor.id, ...combinedPortfolios }}
            portfolioType={investor.is_prospect ? 'prospects' : 'investors'}
            sectionType={SECTOR_EXPOSURE_TYPE}
          />
        </div>
        <div className="sector-exposure-container">
          <SectorExposure
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              SECTOR_EXPOSURE_TYPE
            )}
            id={SECTOR_EXPOSURE_CHART_ID}
            portfolio={combinedPortfolios}
          />
        </div>
        <ScrollToTop />
      </div>

      {/* Top Holdings Section */}
      <div className="client-overview-risk-factor-analysis box-position-analysis">
        <div className="header">
          <BreakdownCustomSecuritiesToggleTitle
            isNewFeature
            portfolio={{ id: investor.id, ...combinedPortfolios }}
            portfolioType={investor.is_prospect ? 'prospects' : 'investors'}
            sectionType={TOP_HOLDINGS_TYPE}
          />
        </div>
        <div className="sector-exposure-container">
          <TopHoldings
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              TOP_HOLDINGS_TYPE
            )}
            portfolio={combinedPortfolios}
          />
        </div>
        <ScrollToTop />
      </div>

      {/* Geographic Exposure Section */}
      <div className="client-overview-risk-factor-analysis box-position-analysis">
        <div className="header" id="geographic-exposure--header">
          <BreakdownCustomSecuritiesToggleTitle
            isNewFeature
            portfolio={{ id: investor.id, ...combinedPortfolios }}
            portfolioType={investor.is_prospect ? 'prospects' : 'investors'}
            sectionType={GEOGRAPHIC_EXPOSURE_TYPE}
          />
        </div>
        <div className="geographic-exposure-container">
          <GeographicExposure
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              GEOGRAPHIC_EXPOSURE_TYPE
            )}
            id={GEOGRAPHIC_EXPOSURE_CHART_ID}
            portfolio={combinedPortfolios}
          />
        </div>
        <ScrollToTop />
      </div>

      {/* Security Type Concentration Section */}
      {!_.isEmpty(positions) && !!totalAssets && (
        <div className="security-type-concentration">
          <h2>Security Type Concentration</h2>
          <SecurityTypeConcentration positions={positions} totalAssets={totalAssets} />
          <p>This chart only considers positions from non-excluded accounts.</p>
          <ScrollToTop />
        </div>
      )}
    </div>
  );
};

InvestorPortfolioInsights.propTypes = {
  combinedPortfolios: PropTypes.object.isRequired,
  breakdownCustomSecurities: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  positions: PropTypes.array.isRequired,
  totalAssets: PropTypes.number.isRequired
};

// mergeProps Function
function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const {
    investor,
    investorAccounts,
    investorsBreakdownCustomSecurities,
    prospect,
    prospectAccounts,
    prospectsBreakdownCustomSecurities
  } = stateProps;

  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const accounts = isProspect ? prospectAccounts : investorAccounts;

  const positions = accounts
    .filter(account => !account.excluded)
    .map(account => account.positions)
    .flat();

  let totalAccounts = 0;
  let totalValue = 0;

  accounts.forEach(account => {
    if (account.excluded === false) {
      totalAccounts += 1;
      totalValue += account.value;
    }
  });

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    accounts,
    positions,
    breakdownCustomSecurities: isProspect
      ? prospectsBreakdownCustomSecurities
      : investorsBreakdownCustomSecurities,
    investor: isProspect ? prospect : investor,
    totalAccounts,
    totalValue
  };
}

export default connect(
  state => ({
    combinedPortfolios: combinedPortfoliosSelector(state),
    investorAccounts: state.investors.viewAccounts || [],
    investorsBreakdownCustomSecurities: state.investors.breakdownCustomSecurities,
    prospect: state.prospects.view,
    prospectAccounts: state.prospects.viewAccounts || [],
    prospectsBreakdownCustomSecurities: state.prospects.breakdownCustomSecurities,
    totalAssets: totalAssetsSelector(state),
    investor: state.investors.view
  }),
  null,
  mergeProps
)(InvestorPortfolioInsights);
