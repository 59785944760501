import MeetingCalendarAlert from 'components/advisor/meeting-calendar-alert';
import RiskToleranceScoreCapacityAndPersonality from 'components/utils/risk-tolerance-score-capacity-and-personality';
import { AdvisorContext } from 'containers/advisor';
import RiskToleranceQuestionnaireReadOnly from 'containers/risk-tolerance-questionnaire/questionnaire/read-only';
import RiskToleranceQuestionnaireCombinedReportViewer from 'containers/risk-tolerance-questionnaire/report/combined/viewer';
import RiskToleranceQuestionnaireSingleReportViewer from 'containers/risk-tolerance-questionnaire/report/single/viewer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import DiscussionTopicsToggleable from '../discussion-topics-toggleable';
import './styles.scss';

const ACCOUNT_PATH = 'account';

const AccountRiskTolerance = ({
  account,
  lastQuestionnaire,
  location,
  questions,
  showDownloadCombinedReport,
  showQuestionnaire,
  hasRTQ
}) => {
  const { showTargetScoreWizard } = useContext(AdvisorContext);

  const {
    target_score_summary: scoreSummary,
    target_score_manual: targetScoreManual,
    target_questionnaire_mail_sent: targetEmailSent
  } = account;

  const isDetailAccount = location.pathname.includes(ACCOUNT_PATH);

  return (
    <div className="risk-container">
      <MeetingCalendarAlert />

      {scoreSummary && (
        <div>
          <RiskToleranceScoreCapacityAndPersonality
            fixedScore={account?.prism_score_summary}
            fixedScoreLabel="Current PRISM"
            lastQuestionnaire={lastQuestionnaire}
            latestUpdate={account?.target_score_updated_at}
            manualScore={targetScoreManual}
            scoreSummary={{ ...scoreSummary, tolerance: lastQuestionnaire?.score_data?.tolerance }}
            showTargetScoreWizard={showTargetScoreWizard}
          />
          {hasRTQ && (
            <DiscussionTopicsToggleable discussionTopics={lastQuestionnaire?.discussion_topics} />
          )}

          {showDownloadCombinedReport && !targetScoreManual && !isDetailAccount && (
            <RiskToleranceQuestionnaireCombinedReportViewer
              discussionTopics={lastQuestionnaire?.discussion_topics}
            />
          )}

          {showQuestionnaire && !targetScoreManual && !_.isEmpty(questions) && (
            <div className="questionnaire">
              <div className="heading">
                <span>Form Answers</span>
                <span className="actions">
                  <RiskToleranceQuestionnaireSingleReportViewer
                    account={account}
                    questionnaire={{ ...lastQuestionnaire, questions }}
                  />
                </span>
              </div>

              <RiskToleranceQuestionnaireReadOnly
                account={account}
                form={`risk-tolerance-questionnaire-read-only__account-${account.id}`}
                questions={questions}
              />
            </div>
          )}
        </div>
      )}

      {!scoreSummary && (
        <div className="missing-risk-score-div">
          <h2>No Risk Tolerance yet</h2>
          <div className="no-tolerance-msg">
            After getting a score, you can generate a proposal and an IPS
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              showTargetScoreWizard();
            }}
          >
            {targetEmailSent ? 'Send Reminder' : 'Assess Risk Tolerance'}
          </button>
        </div>
      )}
    </div>
  );
};

AccountRiskTolerance.defaultProps = {
  lastQuestionnaire: {},
  questions: [],
  hasRTQ: null,
  showDownloadCombinedReport: false,
  showQuestionnaire: false
};

AccountRiskTolerance.propTypes = {
  account: PropTypes.object.isRequired,
  lastQuestionnaire: PropTypes.object,
  location: PropTypes.object.isRequired,
  hasRTQ: PropTypes.object,
  questions: PropTypes.array,
  showDownloadCombinedReport: PropTypes.bool,
  showQuestionnaire: PropTypes.bool
};

export default withRouter(AccountRiskTolerance);
