import { processCashInvestableAssetsQuestion } from '../utils';

/**
 * Formats the cash and investable assets question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
// eslint-disable-next-line import/prefer-default-export
export const formatCashInvestableAssetsQuestion = question => {
  const processedData = processCashInvestableAssetsQuestion(question);
  if (!processedData) return null;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: processedData.cashInvestableAnswer,
    answer_2: processedData.estimatedInvestedAnswer
  };
};
