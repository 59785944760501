/* eslint-disable no-await-in-loop, no-restricted-syntax, prefer-destructuring, react/destructuring-assignment */
import cn from 'classnames';
import EditBoxBaseHeader from 'components/advisor/utils/edit-box/header/base';
import withEditBoxSubmissionHelpers from 'components/advisor/utils/edit-box/hoc';
import { EditBoxDefaultProps, EditBoxPropTypes } from 'components/advisor/utils/edit-box/types';
import { PageBreak, readOnlyContent, SEPARATOR } from 'components/advisor/utils/edit-box/utils';
import { VerboseErrorInput } from 'components/form';
import QuillField from 'components/utils/wysiwyg';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { normalize } from 'utils/form';
import { numToRiskScaleString } from 'utils/utils';
import { IPSPropTypes } from '../types';
import AccountsBreakdown from './accounts-breakdown';
import './styles.scss';
import { processAccountGoalsAssignment, processUncreatedGoals } from './utils';

const IPSExecutiveSummary = props => {
  const { accountProvider, investorGoalsProvider } = useContext(AdvisorContext);

  const [modifiedAccounts, setModifiedAccounts] = useState({});
  const [uncreatedGoals, setUncreatedGoals] = useState({});

  const {
    allowChangeText,
    body,
    className,
    currentAssets,
    customActions,
    editing,
    hidden,
    investmentHorizon,
    ips,
    riskToleranceScore,
    submitting,
    title
  } = props;

  useEffect(() => {
    // allows clearing the `modifiedAccounts` and `uncreatedGoals` states when not editing
    if (!editing) {
      setModifiedAccounts({});
      setUncreatedGoals({});
    }
  }, [editing]);

  const onSectionSave = async values => {
    const { onSectionSave, setSubmitting } = props;

    // adds a dollar sign to make the value appear formatted in the same way as metadata
    // is prepared with the `digest` function
    if (values.currentAssets) values.currentAssets = `$${values.currentAssets}`;

    setSubmitting(true);
    const createdGoals = await processUncreatedGoals(uncreatedGoals, investorGoalsProvider);
    await processAccountGoalsAssignment(modifiedAccounts, createdGoals, accountProvider);

    return onSectionSave(values).finally(() => {
      setModifiedAccounts({});
      setUncreatedGoals({});
      setSubmitting(false);
    });
  };

  const setAccountGoal = (investorId, accountId, goalIdOrName) => {
    setModifiedAccounts(prevModifiedAccounts => ({
      ...prevModifiedAccounts,
      [accountId]: { goal: goalIdOrName ?? null, investor: investorId }
    }));
    if (goalIdOrName && !Number.isFinite(goalIdOrName)) {
      const uncreatedInvestorGoals = uncreatedGoals[investorId];
      if (!uncreatedInvestorGoals?.includes(goalIdOrName))
        setUncreatedGoals(prevUncreatedGoals => {
          const uncreatedInvestorGoals = prevUncreatedGoals[investorId];
          return {
            ...prevUncreatedGoals,
            [investorId]: uncreatedInvestorGoals
              ? [...uncreatedInvestorGoals, goalIdOrName]
              : [goalIdOrName]
          };
        });
    }
  };

  const bodyContent =
    body && body.match(/^(<h2>|<p><h2>)/) ? body : `<h2>${title}</h2>${body || ''}`;

  const initialValues = { body, investmentHorizon, title };
  if (body) initialValues.prepend = body.split(SEPARATOR).splice(1).join('');
  if (currentAssets) initialValues.currentAssets = normalize.float(currentAssets);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={values => values}
      render={({ values }) => (
        <>
          {customActions?.breakSection?.value && !hidden && <PageBreak />}

          <div className={cn('edit-box', 'edit-box-summary', className, { hidden })}>
            <EditBoxBaseHeader {...props} onSectionSave={onSectionSave} values={values} />

            <div className="body">
              {!editing && (
                <>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: bodyContent + values.prepend }} />

                  <div className="read-only-field">
                    <span className="ro-label">Current Assets:</span>
                    <span className="field-box">{currentAssets}</span>
                  </div>

                  <div className="read-only-field">
                    <span className="ro-label">Risk Tolerance:</span>
                    <span
                      className={`risk-badge-with-score risk-level-${Math.ceil(
                        riskToleranceScore
                      )}`}
                    >
                      {riskToleranceScore}
                    </span>
                    <span style={{ marginRight: 4 }}>of 10 -</span>
                    <span className="risk-verbose">{numToRiskScaleString(riskToleranceScore)}</span>
                  </div>

                  <div className="read-only-field">
                    <span className="ro-label">Investment Horizon:</span>
                    <span className="field-box">{investmentHorizon || '-'}</span>
                    years
                  </div>
                </>
              )}

              {editing && (
                <>
                  {allowChangeText ? (
                    <br />
                  ) : (
                    // eslint-disable-next-line react/no-danger
                    <div dangerouslySetInnerHTML={{ __html: readOnlyContent(bodyContent) }} />
                  )}

                  <Field
                    name={allowChangeText ? 'body' : 'prepend'}
                    render={({ input }) => (
                      <QuillField
                        className="body-wysiwyg no-edit"
                        disabled={!editing || submitting}
                        field={input}
                        options={{ height: 200 }}
                      />
                    )}
                  />

                  <Field
                    name="currentAssets"
                    parse={normalize.float}
                    render={({ input, meta }) => (
                      <VerboseErrorInput
                        className="form-control"
                        disabled={submitting}
                        fieldsetClassName="form-group-money"
                        label="Current Assets:"
                        style={{ width: 300 }}
                        type="text"
                        {...input}
                        {...meta}
                      />
                    )}
                  />

                  <Field
                    name="investmentHorizon"
                    render={({ input, meta }) => (
                      <VerboseErrorInput
                        className="form-control"
                        disabled={submitting}
                        label="Investment Horizon:"
                        style={{ width: 300 }}
                        type="text"
                        {...input}
                        {...meta}
                      />
                    )}
                  />
                </>
              )}

              <AccountsBreakdown
                editing={editing}
                ips={ips}
                loading={submitting}
                setAccountGoal={setAccountGoal}
              />
            </div>
          </div>
        </>
      )}
    />
  );
};

IPSExecutiveSummary.propTypes = {
  ...EditBoxPropTypes,
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

IPSExecutiveSummary.defaultProps = { ...EditBoxDefaultProps };

export default withEditBoxSubmissionHelpers(IPSExecutiveSummary);
