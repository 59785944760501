import PropTypes from 'prop-types';

const EditBoxCustomActionsPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    default: PropTypes.bool,
    label: PropTypes.string.isRequired,
    permission: PropTypes.string,
    value: PropTypes.bool
  })
);

export const EditBoxPropTypes = {
  actionsDisabled: PropTypes.bool,
  allowChangeText: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowHide: PropTypes.bool,
  allowSave: PropTypes.bool,
  body: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  customActions: EditBoxCustomActionsPropTypes,
  deleteSection: PropTypes.func,
  disabledEditing: PropTypes.bool,
  editHeader: PropTypes.bool,
  editing: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  footer: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  isCustomSection: PropTypes.bool,
  isHidden: PropTypes.bool,
  preBody: PropTypes.string,
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func,
  setSubmitting: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggleCustomAction: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func,
  toggleVisibility: PropTypes.func.isRequired,
  updateNewSectionPermissions: PropTypes.func
};

export const EditBoxDefaultProps = {
  actionsDisabled: false,
  allowChangeText: false,
  allowEdit: false,
  allowHide: false,
  allowSave: true,
  body: null,
  children: null,
  className: '',
  customActions: {},
  deleteSection: () => {},
  disabledEditing: false,
  editHeader: false,
  editing: false,
  footer: false,
  hidden: false,
  isCustomSection: false,
  isHidden: false,
  preBody: null,
  saveChanges: () => {},
  title: null,
  toggleEditing: () => {},
  updateNewSectionPermissions: () => {}
};
