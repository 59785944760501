import { choicesToOptions, interpolateRef, roundThousands } from 'utils/questionnaire';
import { withCurrencyFormat } from 'utils/utils';

/**
 * Processes the loss reaction question for formatting purposes.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object} - An object containing processed choices and question text.
 */
export const processLossReactionQuestion = question => {
  const refs = question.data?.refs || {};

  // Calculate total assets invested and lost
  const assetsInvested =
    roundThousands(Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])) || 0;
  const assetsLost = roundThousands(assetsInvested * 0.2) || 0;

  // Format assets into human-readable strings
  refs['assets-invested'] = withCurrencyFormat(assetsInvested, 'standard', 0).replace('M', 'MM');
  refs['assets-lost'] = withCurrencyFormat(assetsLost, 'standard', 0).replace('M', 'MM');

  // Interpolate the question text with references
  const questionText = interpolateRef(question.question, refs);

  // Convert choices into usable options
  const choices = choicesToOptions(question.data?.options);

  return {
    choices,
    questionText
  };
};
