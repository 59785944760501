import _ from 'lodash';
import { processLossReactionQuestion } from '../utils';

/**
 * Formats the loss reaction question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
export const formatLossReactionQuestion = question => {
  if (_.isNil(question?.answer)) return null;

  // Use the utility function to process the question
  const { questionText } = processLossReactionQuestion(question);

  return {
    number: question.question_number,
    question: questionText,
    answer_1: question.answer.value
  };
};
