import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { processChoicesQuestion } from '../utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const ChoicesQuestion = ({ question }) => {
  const processedData = processChoicesQuestion(question);
  if (!processedData) return <ReportQuestionSkeleton question={question} />;

  const { selection } = processedData;

  return (
    <ReportQuestion question={question}>
      <Text>{selection}</Text>
    </ReportQuestion>
  );
};

ChoicesQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ChoicesQuestion;
