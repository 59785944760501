import PropTypes from 'prop-types';
import React from 'react';
import { processRangeQuestion } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const RangeReadOnlyQuestion = ({ question }) => {
  const processedData = processRangeQuestion(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  const { value } = processedData;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{value}</p>
    </ReadOnlyQuestion>
  );
};

RangeReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default RangeReadOnlyQuestion;
