/* eslint-disable prefer-destructuring, react/no-danger */
import cn from 'classnames';
import QuillField from 'components/utils/wysiwyg';
import { AdvisorContext } from 'containers/advisor';
import NewTag, { NEW_FEATURED_SECTIONS } from 'containers/advisor/new-tag';
import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import 'react-toggle/style.css';
import EditBoxBaseHeader from './header/base';
import EditBoxEditionHeader from './header/edition';
import withEditBoxSubmissionHelpers from './hoc';
import './styles.scss';
import { EditBoxDefaultProps, EditBoxPropTypes } from './types';
import {
  FOOTER_SEPARATOR,
  getSectionBody,
  INVESTMENT_POLICY_REVIEW,
  PageBreak,
  readOnlyContent,
  SEPARATOR
} from './utils';

const EditBox = props => {
  const { location } = useContext(AdvisorContext);

  const {
    allowChangeText,
    body,
    children,
    className,
    customActions,
    disabledEditing,
    editHeader,
    editing,
    footer,
    hidden,
    isCustomSection,
    onSectionSave,
    preBody,
    propertyKey,
    saveCustomSection,
    submitting,
    title
  } = props;

  const isTemplateView = location.pathname.match('advisor/templates/');
  const sectionBody = getSectionBody({
    body,
    isCustomSection,
    isTemplateView,
    preBody,
    propertyKey,
    title
  });

  const initialValues = { body, title };
  if (body) {
    const separatorType = footer ? FOOTER_SEPARATOR : SEPARATOR;
    initialValues.prepend = body.split(separatorType).splice(1).join('');
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={values => values}
      render={({ values }) => (
        <>
          {((customActions?.breakSection?.value && !hidden) ||
            propertyKey === INVESTMENT_POLICY_REVIEW) && <PageBreak />}

          <div
            className={cn(
              'edit-box advisor-edit-box-container',
              { 'advisor-edit-box-container--add-template': isTemplateView },
              className,
              { hidden }
            )}
          >
            {editHeader || editing ? (
              <Field
                name="title"
                render={({ input }) => (
                  <EditBoxEditionHeader
                    {...props}
                    field={input}
                    onSectionSave={onSectionSave}
                    saveCustomSection={saveCustomSection}
                    submitting={submitting}
                    values={values}
                  />
                )}
              />
            ) : (
              <EditBoxBaseHeader
                {...props}
                onSectionSave={onSectionSave}
                submitting={submitting}
                values={values}
              />
            )}

            {!editing && sectionBody && !disabledEditing && (
              <div
                className={cn('body', { hidden })}
                dangerouslySetInnerHTML={{ __html: sectionBody }}
              />
            )}

            {editing &&
              (isTemplateView ? (
                <Field
                  name="body"
                  render={({ input }) => (
                    <QuillField className="body-wysiwyg" disabled={!editing} field={input} />
                  )}
                />
              ) : (
                <div className={cn('body', { hidden })}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: allowChangeText ? '' : readOnlyContent(body)
                    }}
                  />
                  <Field
                    name={allowChangeText ? 'body' : 'prepend'}
                    render={({ input }) => (
                      <QuillField
                        className="body-wysiwyg no-edit"
                        disabled={!editing}
                        field={input}
                      />
                    )}
                  />
                </div>
              ))}

            {children && !editing && disabledEditing && (
              <div className={cn('body no-edit', { hidden })}>
                <h3>
                  {title} {NEW_FEATURED_SECTIONS.includes(propertyKey) && <NewTag />}
                </h3>
                {children}
              </div>
            )}
          </div>
        </>
      )}
    />
  );
};

EditBox.propTypes = { ...EditBoxPropTypes };

EditBox.defaultProps = { ...EditBoxDefaultProps };

export default withEditBoxSubmissionHelpers(React.memo(EditBox));
