import { View } from '@react-pdf/renderer';
import ProposalBurndown from 'components/advisor/proposal/body/sections/burndown/pdf';
import ProposalCostAnalysis from 'components/advisor/proposal/body/sections/costs/pdf';
import ProposalGeographicExposure from 'components/advisor/proposal/body/sections/geographic-exposure/pdf';
import ProposalHistoricalReturn from 'components/advisor/proposal/body/sections/historical-return/pdf';
import ProposalHoldings from 'components/advisor/proposal/body/sections/holdings/pdf';
import ProposalInvestmentStyle from 'components/advisor/proposal/body/sections/investment-style/pdf';
import ProposalMarketScenarioAnalysis from 'components/advisor/proposal/body/sections/market-scenario-analysis/pdf';
import ProposalMatchingModels from 'components/advisor/proposal/body/sections/matching-models/pdf';
import ProposalPerformance from 'components/advisor/proposal/body/sections/performance/pdf';
import PotentialDownside from 'components/advisor/proposal/body/sections/potential-downside/pdf';
import ProposalPrism from 'components/advisor/proposal/body/sections/prism/pdf';
import ProposalRiskFactorComparison from 'components/advisor/proposal/body/sections/risk-factor-comparison/pdf';
import ProposalRisk from 'components/advisor/proposal/body/sections/risk/pdf';
import ProposalSectorExposure from 'components/advisor/proposal/body/sections/sector-exposure/pdf';
import ProposalSummary from 'components/advisor/proposal/body/sections/summary/pdf';
import ProposalTopHoldings from 'components/advisor/proposal/body/sections/top-holdings/pdf';
import { ProposalPropTypes } from 'components/advisor/proposal/body/sections/types';
import ProposalUpDownCaptureComparison from 'components/advisor/proposal/body/sections/up-down-capture-comparison/pdf';
import ProposalYieldAnalysis from 'components/advisor/proposal/body/sections/yield-analysis/pdf';
import { INVESTOR_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { byNonFixedSections, byTemplateSectionOrder } from 'utils/utils';
import prepareMetadata from '../utils';
import SectionWrapper from './section-wrapper';

const PROPOSAL_COMPONENTS = {
  prism: ProposalPrism,
  matchingModels: ProposalMatchingModels,
  riskFactorComparison: ProposalRiskFactorComparison,
  upDownCaptureComparison: ProposalUpDownCaptureComparison,
  marketScenarioAnalysis: ProposalMarketScenarioAnalysis,
  holdings: ProposalHoldings,
  investmentStyle: ProposalInvestmentStyle,
  sectorExposure: ProposalSectorExposure,
  topHoldings: ProposalTopHoldings,
  regionExposure: ProposalGeographicExposure,
  potentialDownside: PotentialDownside,
  summary: ProposalSummary,
  performance: ProposalPerformance,
  historicalReturn: ProposalHistoricalReturn,
  risk: ProposalRisk,
  burndown: ProposalBurndown,
  cost: ProposalCostAnalysis,
  yieldAnalysis: ProposalYieldAnalysis
};

const ProposalReport = ({
  collapsedHoldings,
  collapsedRegions,
  collapsedSectors,
  collapsedStyles,
  collapsedTopHoldings,
  coverData,
  orientation,
  proposal,
  proposalType,
  setAttachments,
  setTotalPages,
  size,
  subtitle,
  taxonomies,
  title,
  totalPages,
  user,
  withCustomBranding
}) => {
  const getSectionsMetadata = () => {
    const bySectionHidden = ([_, metadata]) => !metadata.hidden;
    const metadata = prepareMetadata(proposal, user, proposalType);
    return Object.entries(metadata).filter(bySectionHidden).sort(byTemplateSectionOrder);
  };

  const sections = getSectionsMetadata();
  const bodySections = sections.filter(byNonFixedSections);
  const footerSection = sections.find(([_, metadata]) => metadata.footer);

  const context = useMemo(
    () => ({
      collapsedHoldings,
      collapsedRegions,
      collapsedSectors,
      collapsedStyles,
      collapsedTopHoldings,
      orientation,
      taxonomies,
      user,
      withCustomBranding
    }),
    [
      JSON.stringify(collapsedHoldings),
      JSON.stringify(collapsedRegions),
      JSON.stringify(collapsedSectors),
      JSON.stringify(collapsedStyles),
      JSON.stringify(collapsedTopHoldings),
      JSON.stringify(taxonomies),
      JSON.stringify(user),
      orientation,
      withCustomBranding
    ]
  );

  const advisor = proposal?.target?.investor?.advisor
    ? {
        ...proposal.target.investor.advisor,
        company: user.advisor.company,
        brand_primary_color:
          proposal.target.investor.advisor.brand_primary_color || user.advisor.brand_primary_color,
        brand_secondary_color:
          proposal.target.investor.advisor.brand_secondary_color ||
          user.advisor.brand_secondary_color,
        logo: proposal.target.investor.advisor.logo || user.advisor.logo
      }
    : user.advisor;
  const withPageNumber = bodySections.every(([_, metadata]) => !metadata.attachment);

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={proposal.subtitle || subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          footer={footerSection ? footerSection[1] : null}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
          withPageNumber={withPageNumber}
        >
          {bodySections.map(([key, metadata]) => (
            <SectionWrapper
              advisor={advisor}
              body={metadata.body}
              component={PROPOSAL_COMPONENTS[key]}
              key={key}
              metadata={metadata}
              orientation={orientation}
              proposal={proposal}
              proposalType={proposalType}
              setAttachments={setAttachments}
              title={metadata.title}
            />
          ))}

          {!!proposal.disclosure && (
            <CustomSection breakSection title="Disclosure" body={proposal.disclosure} />
          )}

          {/* This is a dummy component responsible only for obtaining the total number of pages in the report */}
          <View
            render={({ totalPages: documentTotalPages }) => {
              if (Number.isFinite(documentTotalPages) && documentTotalPages !== totalPages)
                setTotalPages(documentTotalPages);
            }}
          />
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

ProposalReport.propTypes = {
  collapsedHoldings: PropTypes.array.isRequired,
  collapsedRegions: PropTypes.array.isRequired,
  collapsedSectors: PropTypes.array.isRequired,
  collapsedStyles: PropTypes.array.isRequired,
  collapsedTopHoldings: PropTypes.array.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  orientation: PropTypes.string,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string,
  setAttachments: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  taxonomies: PropTypes.array,
  title: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

ProposalReport.defaultProps = {
  coverData: {},
  proposalType: INVESTOR_PROPOSAL_TYPE,
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  taxonomies: [],
  title: 'Investment Proposal',
  withCustomBranding: false
};

export default ProposalReport;
