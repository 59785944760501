import { sectionIsLocked } from 'components/advisor/section-locked/utils';
import DeleteTemplateSectionModal from 'components/advisor/templates/delete-template-section-modal';
import {
  EDIT_RESTRICTED_SECTION,
  LOCKED_SECTION,
  LOCKED_SECTION_HIDDEN,
  VISIBILITY_RESTRICTED_SECTION
} from 'components/advisor/utils/constants';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const EditBoxBaseHeader = ({
  actionsDisabled,
  allowChangeText,
  allowEdit,
  allowHide,
  customActions,
  deleteSection,
  disabledEditing,
  editing,
  hidden,
  isCustomSection,
  onSectionSave,
  propertyKey,
  submitting,
  toggleCustomAction,
  toggleEditing,
  toggleVisibility,
  useTooltip,
  values
}) => {
  const { authProvider, location, user } = useContext(AdvisorContext);

  const isTemplateView = location.pathname.match('advisor/templates/');
  const isTemplateViewWithCompliancePermissionsOrAbove =
    authProvider.hasCompliancePermissionsOrAbove(user) && isTemplateView;
  const canToggleVisibility = allowHide || isTemplateViewWithCompliancePermissionsOrAbove;
  const canToggleEdit =
    allowEdit || allowChangeText || isTemplateViewWithCompliancePermissionsOrAbove;

  const isDisabledComponent = sectionIsLocked(propertyKey, user);

  const onSaveHandler = () => onSectionSave(values);

  return (
    <div className="box-header main">
      <div className="actions">
        {!isDisabledComponent && !canToggleVisibility && !canToggleEdit && (
          <span className="hidden-msg">{LOCKED_SECTION}</span>
        )}
        {!isDisabledComponent && hidden && !canToggleVisibility && (
          <span className="hidden-msg">{VISIBILITY_RESTRICTED_SECTION}</span>
        )}
        {isDisabledComponent && hidden && (
          <span className="hidden-msg">{LOCKED_SECTION_HIDDEN}</span>
        )}
        {!isDisabledComponent && !hidden && !canToggleEdit && (
          <span className="hidden-msg">{EDIT_RESTRICTED_SECTION}</span>
        )}

        <AdministrativePermissionsTooltip
          renderTooltip={useTooltip && actionsDisabled}
          tooltipId={`edit-or-hide-${propertyKey}`}
          wrapperClassName="section-actions"
        >
          {!isDisabledComponent &&
            !editing &&
            Object.entries(customActions).map(([actionId, action]) => {
              const toggleKey = `action-toggle-${propertyKey}-${actionId}`;
              return (
                <label
                  key={`action-${propertyKey}-${actionId}`}
                  htmlFor={toggleKey}
                  className="action-toggle"
                >
                  <Toggle
                    className="toggle-primary"
                    defaultChecked={action.value}
                    disabled={
                      (!action.allowed && !isTemplateViewWithCompliancePermissionsOrAbove) ||
                      actionsDisabled ||
                      submitting ||
                      hidden
                    }
                    key={`${toggleKey}-${action.value}`}
                    onChange={toggleCustomAction(actionId)}
                  />
                  <span>{action.label}</span>
                </label>
              );
            })}

          {!editing && canToggleVisibility && (
            <button
              className="btn btn-secondary btn-secondary-transparent"
              disabled={!canToggleVisibility || actionsDisabled || submitting}
              onClick={toggleVisibility}
              type="button"
            >
              {hidden ? 'Show' : 'Hide'}
            </button>
          )}

          {!isDisabledComponent && !editing && canToggleEdit && !disabledEditing && (
            <button
              className="btn btn-secondary"
              disabled={!canToggleEdit || actionsDisabled || submitting}
              onClick={toggleEditing}
              type="button"
            >
              Edit
            </button>
          )}
        </AdministrativePermissionsTooltip>

        {!editing && isCustomSection && isTemplateViewWithCompliancePermissionsOrAbove && (
          <DeleteTemplateSectionModal
            label="custom section"
            onDelete={deleteSection}
            sectionKey={propertyKey}
            verb="Delete"
          />
        )}

        {editing && (
          <button
            className="btn btn-link text-dark cta"
            onClick={toggleEditing}
            type="button"
            disabled={submitting}
          >
            Cancel
          </button>
        )}

        {editing && (
          <button
            className="btn btn-secondary"
            disabled={submitting}
            onClick={onSaveHandler}
            type="button"
          >
            {submitting ? 'Saving...' : 'Save'}
          </button>
        )}
      </div>
    </div>
  );
};

EditBoxBaseHeader.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowChangeText: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowHide: PropTypes.bool,
  customActions: PropTypes.objectOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string.isRequired,
      permission: PropTypes.string,
      value: PropTypes.bool
    })
  ),
  deleteSection: PropTypes.func,
  disabledEditing: PropTypes.bool,
  editing: PropTypes.bool,
  hidden: PropTypes.bool,
  isCustomSection: PropTypes.bool,
  onSectionSave: PropTypes.func.isRequired,
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  toggleCustomAction: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func,
  toggleVisibility: PropTypes.func.isRequired,
  useTooltip: PropTypes.bool,
  values: PropTypes.object
};

EditBoxBaseHeader.defaultProps = {
  actionsDisabled: false,
  allowChangeText: false,
  allowEdit: false,
  allowHide: false,
  customActions: {},
  deleteSection: () => {},
  disabledEditing: false,
  editing: false,
  hidden: false,
  isCustomSection: false,
  saveChanges: () => {},
  toggleEditing: () => {},
  useTooltip: false,
  values: {}
};

export default EditBoxBaseHeader;
