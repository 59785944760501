/**
 * Formats a numeric question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
export const formatNumericQuestion = question => {
  if (!question?.answer) return null; // Check if answer is null or undefined

  // Return the CSV-compatible object
  return {
    number: question.question_number,
    question: question.question,
    answer_1: question.answer.value
  };
};
