import EditBoxText from 'components/advisor/utils/edit-box-text';
import { BASE_REACT_SUMMERNOTE_OPTIONS } from 'components/utils/wysiwyg/constants';
import PropTypes from 'prop-types';
import React from 'react';

const CUSTOM_OPTIONS = {
  ...BASE_REACT_SUMMERNOTE_OPTIONS,
  toolbar: [
    ['insert', ['link']],
    ['misc', ['undo', 'redo']]
  ],
  maxLength: 250,
  height: 180
};

const FooterSection = props => (
  <EditBoxText {...props} className="sc-footer" disableTitleChange footer />
);

FooterSection.defaultProps = {
  options: CUSTOM_OPTIONS
};

FooterSection.propTypes = {
  options: PropTypes.object
};

export default FooterSection;
