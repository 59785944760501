import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export const DEFAULT_AMOUNT = 100000;
export const DEFAULT_VALUE = 2;

export const POSITIVE_COLOR = '#6bb44d';
export const NEGATIVE_COLOR = '#bb3f3d';

export const formatPotentialGainLossText = ({
  defaultAmount = DEFAULT_AMOUNT,
  positiveFormat = str => str,
  negativeFormat = str => str,
  option
}) => {
  if (!option) return '';

  const gain = positiveFormat(((option.positive * defaultAmount) / 100).toFixed(2));
  const loss = negativeFormat(((-1 * option.negative * defaultAmount) / 100).toFixed(2));

  return `Potential gain: ${gain}, Potential loss: ${loss}`;
};

export const PotentialGainLossText = ({
  defaultAmount,
  negativeFormat,
  positiveFormat,
  option
}) => (
  <FormattedMessage
    id="rtq.question-5.potential-gain-loss"
    values={{
      positive: positiveFormat,
      negative: negativeFormat,
      gain: <FormattedNumber value={(option.positive * defaultAmount) / 100} format="currency" />,
      loss: (
        <FormattedNumber value={(-1 * option.negative * defaultAmount) / 100} format="currency" />
      )
    }}
  />
);

PotentialGainLossText.defaultProps = {
  negativeFormat: str => str,
  positiveFormat: str => str
};

PotentialGainLossText.propTypes = {
  defaultAmount: PropTypes.number.isRequired,
  negativeFormat: PropTypes.func,
  positiveFormat: PropTypes.func,
  option: PropTypes.object.isRequired
};
