import cn from 'classnames';
import {
  ACCOUNT_PROPOSAL_TYPE,
  INVESTOR_PROPOSAL_TYPE,
  PROSPECT_PROPOSAL_TYPE
} from 'components/advisor/proposal/constants';
import { sectionIsLocked } from 'components/advisor/section-locked/utils';
import { setProposalSigners } from 'components/advisor/templates/sections/signature/digital-signature/utils';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { byTemplateSectionOrder } from 'utils/utils';
import prepareMetadata from '../utils';
import ProposalBurndown from './sections/burndown';
import ProposalCostAnalysis from './sections/costs';
import ProposalCoverSection from './sections/cover';
import ProposalCustomSection from './sections/custom';
import ProposalEmbeddedDocument from './sections/embedded-document';
import ProposalFooterSection from './sections/footer';
import ProposalGeographicExposure from './sections/geographic-exposure';
import ProposalHistoricalReturn from './sections/historical-return';
import ProposalHoldings from './sections/holdings';
import ProposalInvestmentStyle from './sections/investment-style';
import ProposalMarketScenarioAnalysis from './sections/market-scenario-analysis';
import ProposalMatchingModels from './sections/matching-models';
import ProposalPerformance from './sections/performance';
import PotentialDownside from './sections/potential-downside';
import ProposalPrism from './sections/prism';
import ProposalRisk from './sections/risk';
import ProposalRiskFactorComparison from './sections/risk-factor-comparison';
import ProposalSectorExposure from './sections/sector-exposure';
import ProposalSignature from './sections/signature';
import ProposalSummary from './sections/summary';
import ProposalTopHoldings from './sections/top-holdings';
import { ProposalPropTypes } from './sections/types';
import ProposalUpDownCaptureComparison from './sections/up-down-capture-comparison';
import ProposalYieldAnalysis from './sections/yield-analysis';
import './styles.scss';

const PROPOSAL_COMPONENTS = {
  prism: ProposalPrism,
  matchingModels: ProposalMatchingModels,
  riskFactorComparison: ProposalRiskFactorComparison,
  upDownCaptureComparison: ProposalUpDownCaptureComparison,
  marketScenarioAnalysis: ProposalMarketScenarioAnalysis,
  holdings: ProposalHoldings,
  investmentStyle: ProposalInvestmentStyle,
  sectorExposure: ProposalSectorExposure,
  topHoldings: ProposalTopHoldings,
  regionExposure: ProposalGeographicExposure,
  potentialDownside: PotentialDownside,
  summary: ProposalSummary,
  performance: ProposalPerformance,
  historicalReturn: ProposalHistoricalReturn,
  risk: ProposalRisk,
  burndown: ProposalBurndown,
  cost: ProposalCostAnalysis,
  yieldAnalysis: ProposalYieldAnalysis,
  _cover: ProposalCoverSection,
  _footer: ProposalFooterSection
};

const ProposalSection = React.memo(
  ({ component, ...rest }) => {
    const { user } = useContext(AdvisorContext);

    const isSectionLocked = sectionIsLocked(rest.propertyKey, user);

    if (rest.metadata.hidden && isSectionLocked) return null;
    if (rest.metadata.hidden && !rest.metadata.allowHide) return null;
    if (rest.metadata.attachment) return <ProposalEmbeddedDocument {...rest} />;
    if (rest.metadata.signature) {
      if (
        rest.proposalType === INVESTOR_PROPOSAL_TYPE ||
        rest.proposalType === PROSPECT_PROPOSAL_TYPE
      )
        return <ProposalSignature {...rest} />;
      return null;
    }

    const Component = component ?? ProposalCustomSection;
    return <Component {...rest} />;
  },
  (prevProps, nextProps) =>
    _.isEqual(prevProps.actionsDisabled, nextProps.actionsDisabled) &&
    _.isEqual(prevProps.proposal, nextProps.proposal) &&
    _.isEqual(prevProps.metadata, nextProps.metadata)
);

ProposalSection.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

ProposalSection.defaultProps = {
  component: null
};

const ProposalBody = ({
  className,
  currentReport,
  managers,
  proposal,
  proposalCharts,
  proposalType,
  setMetadata
}) => {
  const { proposalProvider, user } = useContext(AdvisorContext);

  const [actionsDisabled, setActionsDisabled] = useState(false);

  const getSectionsMetadata = () => {
    const metadata = prepareMetadata(proposal, user, proposalType);
    return Object.entries(metadata).sort(byTemplateSectionOrder);
  };

  const setSectionMetadata = (key, value) => {
    setActionsDisabled(true);
    const metadata = prepareMetadata(proposal, user, proposalType);
    return setMetadata({ ...metadata, [key]: value }).finally(() => {
      setActionsDisabled(false);
    });
  };

  const sections = getSectionsMetadata();

  useEffect(() => {
    if (user?.advisor?.company?.digital_signature_enabled) {
      const content = prepareMetadata(proposal, user, proposalType);
      const missingSignersSections = Object.entries(content).filter(
        ([, data]) => !!data.signature && !data.signers
      );
      if (missingSignersSections.length) {
        setActionsDisabled(true);
        setProposalSigners(content, proposal, proposalProvider).finally(() => {
          setActionsDisabled(false);
        });
      }
    }
  }, []);

  return (
    <div className={cn(className || 'proposal-body')}>
      {sections.map(([key, metadata]) => (
        <ProposalSection
          actionsDisabled={actionsDisabled}
          component={PROPOSAL_COMPONENTS[key]}
          key={`${key}Section`}
          managers={managers}
          metadata={metadata}
          propertyKey={key}
          proposal={proposal}
          proposalCharts={proposalCharts}
          proposalType={proposalType}
          setMetadata={setSectionMetadata}
        />
      ))}

      {proposal.disclosure && (
        <section id="disclosure">
          <h3>Disclosure</h3>
          <p dangerouslySetInnerHTML={{ __html: proposal.disclosure }} />
        </section>
      )}
    </div>
  );
};

ProposalBody.defaultProps = {
  className: '',
  proposalType: ACCOUNT_PROPOSAL_TYPE
};

ProposalBody.propTypes = {
  className: PropTypes.string,
  currentReport: PropTypes.object.isRequired,
  managers: PropTypes.array.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalBody;
