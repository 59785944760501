import FooterSection from 'components/advisor/templates/sections/footer';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes } from '../types';

const ProposalFooterSection = ({ actionsDisabled, metadata, propertyKey, setMetadata }) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });
  return <FooterSection {...proposalMetadata} />;
};

ProposalFooterSection.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalFooterSection;
