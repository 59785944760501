import cn from 'classnames';
import DisplayScore from 'components/utils/DisplayScore';
import Link from 'components/utils/link';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { trackAmplitudeEvent } from 'utils/tracking';
import './styles.scss';

export const tabs = {
  OVERVIEW: 'overview',
  PRISM: 'prism',
  RISK_TOLERANCE: 'risk-tolerance'
};

class AccountDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: tabs.OVERVIEW };
  }

  componentDidMount() {
    const { tab } = this.state;
    const { account } = this.props;
    trackAmplitudeEvent('account.viewed', { id: account?.id, tab });
    this.setCurrentTab();
  }

  componentDidUpdate() {
    this.setCurrentTab();
  }

  setCurrentTab() {
    const { tab } = this.state;
    const { location, account } = this.props;

    Object.entries(tabs).some(([_, value]) => {
      if (location.pathname.includes(value) && tab !== value) {
        trackAmplitudeEvent('account.viewed', { id: account?.id, tab: value });
        this.goTo(value);
        return null;
      }
      return null;
    });
  }

  goTo = tab => {
    this.setState({ tab });
  };

  render() {
    const { isProspectSection } = this.context;
    const { account, children, investor } = this.props;
    const { tab } = this.state;

    const prismOverall =
      account && account.prism_score_summary && account.prism_score_summary.overall;

    const targetOverall =
      account && account.target_score_summary && account.target_score_summary.overall;

    const clientPath = isProspectSection ? 'prospects' : 'investors';

    const investorId = investor ? investor.id : account.investor.id;

    const isStrategy = account?.target_model?.is_strategy;

    return (
      <div className="account-details-view">
        <ul className="tabs">
          <li className={cn({ active: tab === tabs.OVERVIEW })}>
            <Link
              className="btn-transparent"
              to={`/advisor/${clientPath}/${investorId}/account/${account.id}/${tabs.OVERVIEW}`}
            >
              Overview
            </Link>
          </li>
          <li
            className={cn({
              active: tab === tabs.PRISM
            })}
          >
            <Link
              disabled={!prismOverall}
              to={`/advisor/${clientPath}/${investorId}/account/${account.id}/${tabs.PRISM}`}
            >
              {prismOverall && (
                <DisplayScore score={prismOverall} className="header" isStrategy={isStrategy} />
              )}{' '}
              Portfolio Risk
            </Link>
          </li>
          <li
            className={cn({
              active: tab === tabs.RISK_TOLERANCE
            })}
          >
            <Link
              to={`/advisor/${clientPath}/${investorId}/account/${account.id}/${tabs.RISK_TOLERANCE}`}
            >
              {targetOverall && (
                <DisplayScore
                  score={targetOverall}
                  manualScore={account.target_score_manual}
                  className="header"
                />
              )}{' '}
              Risk Tolerance
            </Link>
          </li>
        </ul>

        {children}
      </div>
    );
  }
}

AccountDetailsView.propTypes = {
  account: PropTypes.object.isRequired,
  benchmark: PropTypes.array,
  children: PropTypes.element.isRequired,
  investor: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  questions: PropTypes.array,
  riskAnalysis: PropTypes.object,
  toggleAccountStatus: PropTypes.func.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired
};

AccountDetailsView.defaultProps = {
  benchmark: [],
  questions: [],
  riskAnalysis: {}
};

AccountDetailsView.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  isProspectSection: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

export default withRouter(AccountDetailsView);
