/* eslint-disable react/no-danger */
import cn from 'classnames';
import SectionLocked from 'components/advisor/section-locked';
import { SECTION_LOCKED_MAP, sectionIsLocked } from 'components/advisor/section-locked/utils';
import 'components/advisor/utils/edit-box/styles.scss';
import {
  FOOTER_SEPARATOR,
  getSectionBody,
  PageBreak,
  readOnlyContent,
  SEPARATOR
} from 'components/advisor/utils/edit-box/utils';
import Choice from 'components/form/choice';
import QuillField from 'components/utils/wysiwyg';
import { AdvisorContext } from 'containers/advisor';
import humps from 'humps';
import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import EditBoxBaseHeader from '../edit-box/header/base';
import EditBoxEditionHeader from '../edit-box/header/edition';
import withEditBoxSubmissionHelpers from '../edit-box/hoc';
import { EditBoxDefaultProps, EditBoxPropTypes } from '../edit-box/types';

const EditBoxText = props => {
  const { authProvider, location, user } = useContext(AdvisorContext);

  const {
    allowChangeText,
    allowEdit,
    allowHide,
    body,
    children,
    className,
    customActions,
    disableTitleChange,
    editHeader,
    editing,
    footer,
    hidden,
    isCustomSection,
    onSectionSave,
    options,
    placeholder,
    preBody,
    propertyKey,
    saveChanges,
    saveCustomSection,
    submitting,
    title,
    updateNewSectionPermissions
  } = props;

  const setPermissions = values => {
    const commonAttrs = { allowChangeText, allowEdit, allowHide, hidden, title };
    if (editHeader && updateNewSectionPermissions)
      return updateNewSectionPermissions({ ...commonAttrs, ...values });
    return saveChanges(propertyKey, { ...commonAttrs, body, ...values });
  };

  const setPermissionsHandler = permissions => () => setPermissions(permissions);

  const isLockedSection = sectionIsLocked(propertyKey, user);
  const isTemplateView = location.pathname.match('advisor/templates/');
  const isTemplateViewWithCompliancePermissionsOrAbove =
    authProvider.hasCompliancePermissionsOrAbove(user) && isTemplateView;
  const sectionBody = getSectionBody({
    body,
    isCustomSection,
    isTemplateView,
    preBody,
    propertyKey,
    title
  });

  const initialValues = { body, title };
  if (body) {
    const separatorType = footer ? FOOTER_SEPARATOR : SEPARATOR;
    initialValues.prepend = body.split(separatorType).splice(1).join('');
  }

  // the propertyKey is converted from camelCase to kebab-case
  // to follow HTML attribute naming standard (used in SASS styles)
  const kebabCasePropertyKey = humps.decamelize(propertyKey, { separator: '-' });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={values => values}
      render={({ values }) => (
        <>
          {customActions?.breakSection?.value && !hidden && <PageBreak />}

          <section
            id={`${kebabCasePropertyKey}-section`}
            className={cn(
              'edit-box advisor-edit-box-container',
              { 'advisor-edit-box-container--add-template': isTemplateView },
              className,
              { hidden }
            )}
          >
            {!disableTitleChange && (editHeader || editing) ? (
              <Field
                name="title"
                render={({ input }) => (
                  <EditBoxEditionHeader
                    {...props}
                    field={input}
                    onSectionSave={onSectionSave}
                    saveCustomSection={saveCustomSection}
                    submitting={submitting}
                    values={values}
                  />
                )}
              />
            ) : (
              <EditBoxBaseHeader
                {...props}
                onSectionSave={onSectionSave}
                submitting={submitting}
                values={values}
              />
            )}

            {!isLockedSection && (
              <div className={cn('body', { editing, hidden })}>
                {editing && (
                  <>
                    {isTemplateViewWithCompliancePermissionsOrAbove && (
                      <Field
                        name="body"
                        render={({ input }) => (
                          <QuillField
                            className="body-wysiwyg"
                            disabled={!editing}
                            field={input}
                            options={{ height: 200, ...options }}
                          />
                        )}
                      />
                    )}
                    {!isTemplateViewWithCompliancePermissionsOrAbove && (
                      <>
                        <div
                          className={cn('body', { hidden })}
                          dangerouslySetInnerHTML={{
                            __html: allowChangeText ? '' : readOnlyContent(body)
                          }}
                        />
                        <Field
                          name={allowChangeText ? 'body' : 'prepend'}
                          render={({ input }) => (
                            <QuillField
                              className="body-wysiwyg no-edit"
                              disabled={!editing}
                              field={input}
                              options={{ height: 200, ...options }}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}

                {!editing && sectionBody && (
                  <>
                    <div
                      className="body-content"
                      dangerouslySetInnerHTML={{ __html: sectionBody }}
                    />
                    {placeholder && <div className="placeholder">{placeholder} will be here.</div>}
                  </>
                )}

                {children && children}
              </div>
            )}

            {isLockedSection && (
              <SectionLocked
                id={SECTION_LOCKED_MAP[propertyKey].portfolioRiskId}
                name={SECTION_LOCKED_MAP[propertyKey].name}
              />
            )}

            {!isLockedSection && isTemplateViewWithCompliancePermissionsOrAbove && (
              <div className="permissions">
                <span className="permissions__title">Actions Allowed:</span>
                <ul>
                  <li>
                    <Choice
                      title="Add more content"
                      checked={allowEdit}
                      disabled={false}
                      toggle={setPermissionsHandler({ allowEdit: !allowEdit })}
                    />
                  </li>
                  <li>
                    <Choice
                      title={`${hidden ? 'Show' : 'Hide'} this section`}
                      checked={allowHide}
                      disabled={false}
                      toggle={setPermissionsHandler({ allowHide: !allowHide })}
                    />
                  </li>
                  <li>
                    <Choice
                      title="Change text"
                      checked={allowChangeText}
                      disabled={false}
                      toggle={setPermissionsHandler({ allowChangeText: !allowChangeText })}
                    />
                  </li>
                  {Object.entries(customActions)
                    .filter(([_, action]) => action.permission)
                    .map(([actionId, action]) => (
                      <li key={`action-${propertyKey}-${actionId}`}>
                        <Choice
                          title={action.permission}
                          checked={action.allowed}
                          disabled={false}
                          toggle={setPermissionsHandler({ [actionId]: !action.allowed })}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </section>
        </>
      )}
    />
  );
};

EditBoxText.propTypes = { ...EditBoxPropTypes };

EditBoxText.defaultProps = { ...EditBoxDefaultProps };

export default withEditBoxSubmissionHelpers(React.memo(EditBoxText));
