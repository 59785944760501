import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { processBirthdayRetirementData } from '../utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const BirthdayRetirementQuestion = ({ question }) => {
  const processedData = processBirthdayRetirementData(question);
  if (!processedData) return <ReportQuestionSkeleton question={question} />;

  return (
    <ReportQuestion question={question}>
      <View>
        <Text>{processedData.answer1}</Text>
      </View>
      <View>
        <Text>{processedData.answer2}</Text>
      </View>
    </ReportQuestion>
  );
};

BirthdayRetirementQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default BirthdayRetirementQuestion;
