/* eslint-disable prefer-destructuring */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FOOTER_SEPARATOR, SEPARATOR } from '../utils';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withEditBoxSubmissionHelpers = WrappedComponent => {
  const WithEditBoxSubmissionHelpers = props => {
    const [submitting, setSubmitting] = useState(false);

    const onSectionSave = values => {
      const {
        allowChangeText,
        allowEdit,
        allowHide,
        footer,
        hidden,
        propertyKey,
        saveChanges,
        toggleEditing
      } = props;

      const separatorType = footer ? FOOTER_SEPARATOR : SEPARATOR;
      const prepend = values.prepend ? `${separatorType} ${values.prepend}` : '';
      const body = values.body ? values.body.split(separatorType)[0] : '';
      if (allowChangeText) values.body = body;
      else values.body = body + prepend;

      setSubmitting(true);
      return saveChanges(propertyKey, { ...values, allowEdit, allowHide, hidden })
        .then(() => {
          toast.success(() => 'Changes Saved successfully.');
          toggleEditing();
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    const saveCustomSection = values => {
      const { allowEdit, allowHide, allowChangeText, saveChanges, toggleEditing } = props;
      values.body = values.body ? values.body.split(SEPARATOR)[0] : '';

      setSubmitting(true);
      return saveChanges(values.title.trim(), {
        ...values,
        allowEdit,
        allowHide,
        allowChangeText
      })
        .then(() => {
          toggleEditing();
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    const toggleVisibility = () => {
      const { toggleVisibility } = props;
      setSubmitting(true);
      return toggleVisibility().finally(() => {
        setSubmitting(false);
      });
    };

    return (
      <WrappedComponent
        {...props}
        onSectionSave={onSectionSave}
        saveCustomSection={saveCustomSection}
        setSubmitting={setSubmitting}
        submitting={submitting}
        toggleVisibility={toggleVisibility}
      />
    );
  };

  WithEditBoxSubmissionHelpers.displayName = `WithEditBoxSubmissionHelpers(${getDisplayName(
    WrappedComponent
  )})`;

  WithEditBoxSubmissionHelpers.propTypes = {
    allowChangeText: PropTypes.bool,
    allowEdit: PropTypes.bool,
    allowHide: PropTypes.bool,
    allowSave: PropTypes.bool,
    footer: PropTypes.bool,
    hidden: PropTypes.bool,
    propertyKey: PropTypes.string.isRequired,
    saveChanges: PropTypes.func,
    title: PropTypes.string,
    toggleEditing: PropTypes.func,
    toggleVisibility: PropTypes.func
  };

  WithEditBoxSubmissionHelpers.defaultProps = {
    allowChangeText: false,
    allowEdit: false,
    allowHide: false,
    allowSave: true,
    footer: false,
    hidden: false,
    saveChanges: () => {},
    title: null,
    toggleEditing: () => {},
    toggleVisibility: () => {}
  };

  return WithEditBoxSubmissionHelpers;
};

export default withEditBoxSubmissionHelpers;
