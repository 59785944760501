import _ from 'lodash';
import { getAddress } from '../utils';

export const formatAddressQuestion = question => {
  if (_.isNil(question?.answer)) return null;
  const formattedAddress = getAddress(question.answer);

  // Return the object in the required format
  return {
    number: question.question_number,
    question: question.question,
    answer_1: formattedAddress
  };
};
