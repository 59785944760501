import { formatMoney, formatPercentage } from 'utils/utils';
import { questionTypes as qt } from 'constants/questionnaire';

/**
 * Processes Expected Return question data for reusability.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Processed data with formatted answers or null if invalid.
 */
export const processExpectedReturnQuestion = question => {
  if (!question || !question.answer) return null;

  // Handle LEGACY question type
  if (question.question_type === qt.LEGACY)
    return {
      questionText: question.question,
      answer1: formatMoney(question.answer.amount),
      answer2: null
    };

  return {
    questionText: question.question,
    answer1: `Yearly return expected: ${formatPercentage(question.answer.expected, 1)}`,
    answer2: `Yearly return needed: ${formatPercentage(question.answer.needed, 1)}`
  };
};
