import _ from 'lodash';
import { reducer as formReducer } from 'redux-form';
import { normalize } from 'utils/form';
import { sanitizeUrl } from 'utils/utils';

// would be nice to split and place it in components (when form api lets)
const form = formReducer.normalize({
  editMeetingCalendar: {
    meeting_calendar_link: value => sanitizeUrl(value)
  },
  editModel: {
    'positions[].value': value => normalize.nDecimalPoints(2, value)
  },
  generateProposal: {
    benchmark: value => {
      if (Array.isArray(value) && _.isEmpty(value)) return undefined;
      return value;
    },
    startingValue: value => normalize.float(value),
    yearlyWithdrawalAmount: value => normalize.float(value),
    yearlyWithdrawalRate: (value, previousValue) =>
      normalize.floatPercentage(5, 2, value, previousValue)
  },
  generateIPS: {
    startingValue: value => normalize.float(value),
    yearlyWithdrawalAmount: value => normalize.float(value),
    yearlyWithdrawalRate: (value, previousValue) =>
      normalize.floatPercentage(5, 2, value, previousValue)
  }
});

export default form;
