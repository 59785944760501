import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { processExpectedReturnQuestion } from '../utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const ExpectedReturnQuestion = ({ question }) => {
  const processedData = processExpectedReturnQuestion(question);
  if (!processedData) return <ReportQuestionSkeleton question={question} />;

  const { questionText, answer1, answer2 } = processedData;

  return (
    <ReportQuestion question={{ ...question, question: questionText }}>
      {answer1 && (
        <View>
          <Text>{answer1}</Text>
        </View>
      )}
      {answer2 && (
        <View>
          <Text>{answer2}</Text>
        </View>
      )}
    </ReportQuestion>
  );
};

ExpectedReturnQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ExpectedReturnQuestion;
