import { choicesToOptions, interpolateRef, roundThousands } from 'utils/questionnaire';
import { withCurrencyFormat } from 'utils/utils';

/**
 * Processes portfolio concentration question for formatting purposes.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object} - An object containing processed choices, initial investment, and question text.
 */
export const processPortfolioConcentrationQuestion = question => {
  const refs = question.data?.refs || {};

  // Calculate total assets invested
  const initialInvestment = roundThousands(
    Number(refs['liquid-assets']) * Number(refs['percentage-assets-invested'])
  );

  // Format the invested amount
  refs['assets-invested'] =
    withCurrencyFormat(initialInvestment, 'standard', 0).replace('M', 'MM') || 0;

  // Interpolate question text with references
  const questionText = interpolateRef(question.question, refs);

  // Convert choices into usable options
  const choices = choicesToOptions(question.data?.options);

  return {
    choices,
    initialInvestment,
    questionText
  };
};
