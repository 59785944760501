import PropTypes from 'prop-types';
import React from 'react';
import { processExpectedReturnQuestion } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const ExpectedReturnReadOnlyQuestion = ({ question }) => {
  const processedData = processExpectedReturnQuestion(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  const { questionText, answer1, answer2 } = processedData;

  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      {answer1 && <p>{answer1}</p>}
      {answer2 && <p>{answer2}</p>}
    </ReadOnlyQuestion>
  );
};

ExpectedReturnReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ExpectedReturnReadOnlyQuestion;
