import _ from 'lodash';
import { formatMoney } from 'utils/utils';
import { TYPE_ANNUAL, TYPE_ONCE } from '../hooks';

/**
 * Formats the value requirement question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
export const formatValueRequirementCSVQuestion = question => {
  if (_.isNil(question?.answer)) return null;

  // Construct the answer text based on the type and formatted amount
  const formattedAmount = formatMoney(question.answer.amount);
  const formattedAnswer =
    question.answer.type === TYPE_ANNUAL
      ? `I will need an annual income of ${formattedAmount} from this investment`
      : question.answer.type === TYPE_ONCE
        ? `I need ${formattedAmount} from this investment`
        : '';

  // Return the CSV-compatible object
  return {
    number: question.question_number,
    question: question.question,
    answer_1: formattedAnswer
  };
};
