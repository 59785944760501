import { getWithdrawalRequirementAnswerCSV } from '../utils';

/**
 * Formats the withdrawal requirement question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @param {Object} intl - The intl object from react-intl.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
// eslint-disable-next-line import/prefer-default-export
export const formatWithdrawalRequirementCSVQuestion = (question, intl) => {
  if (!question?.answer) return null;

  const formattedAnswer = getWithdrawalRequirementAnswerCSV(question, intl);

  // Return the CSV-compatible object
  return {
    number: question.question_number,
    question: question.question,
    answer_1: formattedAnswer
  };
};
