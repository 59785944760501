/**
 * Processes the choices question data for reusability.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - Processed data with the formatted selection or null if invalid.
 */
export const processChoicesQuestion = question => {
  if (!question?.answer) return null;

  // Generate the selection text by filtering and mapping options
  const selection = question.data?.options
    ?.filter(choice => question.answer.value.includes(choice.value))
    .map(c => c.display_text ?? c.text ?? c.value)
    .join(', ');

  return { selection };
};
