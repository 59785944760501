import { processExpectedReturnQuestion } from '../utils';

/**
 * Formats the expected return question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
export const formatExpectedReturnQuestion = question => {
  const processedData = processExpectedReturnQuestion(question);
  if (!processedData) return null;

  const { questionText, answer1, answer2 } = processedData;

  return {
    number: question.question_number,
    question: questionText,
    answer_1: answer1,
    answer_2: answer2
  };
};
