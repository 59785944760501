import PropTypes from 'prop-types';
import React from 'react';
import { processChoicesQuestion } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const ChoicesReadOnlyQuestion = ({ question }) => {
  const processedData = processChoicesQuestion(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  const { selection } = processedData;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{selection}</p>
    </ReadOnlyQuestion>
  );
};

ChoicesReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default ChoicesReadOnlyQuestion;
