import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { processGoalApproachQuestion } from '../utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const GoalApproachQuestion = ({ question }) => {
  const processedData = processGoalApproachQuestion(question);
  if (!processedData) return <ReportQuestionSkeleton question={question} />;

  const { goalSelection, approachSelection } = processedData;

  return (
    <ReportQuestion question={question}>
      <Text>Goal: {goalSelection}</Text>
      <Text>Approach: {approachSelection}</Text>
    </ReportQuestion>
  );
};

GoalApproachQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default GoalApproachQuestion;
