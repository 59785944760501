import cn from 'classnames';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import 'react-toggle/style.css';

const EditBoxEditionHeader = ({
  allowChangeText,
  allowSave,
  field,
  onSectionSave,
  saveCustomSection,
  submitting,
  toggleEditing,
  values
}) => {
  const { authProvider, location, user } = useContext(AdvisorContext);

  const isTemplateView = location.pathname.match('advisor/templates/');
  const hasCompliancePermissionsOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

  const onSaveHandler = () => (isTemplateView ? saveCustomSection(values) : onSectionSave(values));
  const toggleEditingHandler = () => toggleEditing();

  return (
    <>
      <div className="advisor-edit-box-container__edit-header__buttons">
        <button
          className="advisor-edit-box-container__edit-header__buttons__cancel"
          disabled={submitting}
          onClick={toggleEditingHandler}
          type="button"
        >
          Cancel
        </button>
        <button
          className="btn btn-secondary advisor-edit-box-container__edit-header__buttons__save"
          disabled={submitting || !allowSave}
          onClick={onSaveHandler}
          type="button"
        >
          {submitting ? 'Saving...' : 'Save'}
        </button>
      </div>

      <div
        className={cn('advisor-edit-box-container__edit-header__input', {
          'advisor-edit-box-container__edit-header__input--client-view': !isTemplateView
        })}
      >
        <input
          {...field}
          name="title"
          type="text"
          className={cn({
            'advisor-edit-box-container__edit-header__input--client-view': !isTemplateView
          })}
          disabled={!isTemplateView && !hasCompliancePermissionsOrAbove && !allowChangeText}
          placeholder="Custom section title..."
        />
      </div>
    </>
  );
};

EditBoxEditionHeader.propTypes = {
  allowChangeText: PropTypes.bool,
  allowSave: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  onSectionSave: PropTypes.func.isRequired,
  saveCustomSection: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func,
  values: PropTypes.object
};

EditBoxEditionHeader.defaultProps = {
  allowChangeText: false,
  toggleEditing: () => {},
  values: {}
};

export default EditBoxEditionHeader;
