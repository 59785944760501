import _ from 'lodash';
import { formatPercentage } from 'utils/utils';
import { processPortfolioConcentrationQuestion } from '../utils';

/**
 * Formats the portfolio concentration question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
export const formatPortfolioConcentrationQuestion = question => {
  if (_.isNil(question?.answer)) return null;

  // Use the utility function to process the question
  const { questionText } = processPortfolioConcentrationQuestion(question);

  // Format the answer
  const formattedAnswer = formatPercentage(question.answer.value);

  // Return the object in the required format
  return {
    number: question.question_number,
    question: questionText,
    answer_1: formattedAnswer
  };
};
