import { questionSlugs as qs } from 'constants/questionnaire';
import { formatPercentage, withCurrencyFormat } from 'utils/utils';

/**
 * Processes the Range question data for reusability.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - Processed data with formatted value or null if invalid.
 */
export const processRangeQuestion = question => {
  if (!question?.answer) return null;

  let { value } = question.answer;

  // Format value based on question data
  if (question.data.format === 'currency') value = withCurrencyFormat(value, 'standard', 0);
  else if (question.data.format === 'percent') value = formatPercentage(value, 100, 0);
  else if (question.slug === qs.TIME_HORIZON) value = value > 0 ? `In ${value} Year(s)` : `Now`;

  return { value };
};
