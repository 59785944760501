import { isNil } from 'lodash';

/**
 * Formats a text-based question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no valid answer is provided.
 */
export const formatTextQuestion = question => {
  if (isNil(question?.answer)) return null;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: question.answer.value || ''
  };
};
