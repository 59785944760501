import { formatMoney, formatPercentage } from 'utils/utils';
import { useAnnualTaxableIncomeQuestionDetails } from './hooks';

/**
 * Processes Annual Taxable Income Question data for reusability.
 * @param {Object} question - The question object
 * @returns {Object|null} - Processed data with formatted answers or null if invalid
 */
export const processAnnualTaxableIncomeQuestion = question => {
  if (!question?.answer) return null;

  const { estimatedAnnualIncomeText, estimatedYearlyContributionText, questionText } =
    useAnnualTaxableIncomeQuestionDetails({ question });

  const incomeAnswer = `${estimatedAnnualIncomeText} ${
    question.answer.income ? formatMoney(question.answer.income) : '-'
  }`;

  const contributionAnswer = `${estimatedYearlyContributionText} ${
    question.answer.contribution
      ? `${formatPercentage(question.answer.contribution, 100)} (${formatMoney(
          question.answer.income * question.answer.contribution
        )})`
      : '-'
  }`;

  return {
    questionText,
    incomeAnswer,
    contributionAnswer
  };
};
