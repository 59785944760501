import { formatMoney, formatPercentage } from 'utils/utils';

/**
 * Processes Cash and Investable Assets question data for reusability.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Processed data with formatted answers or null if invalid.
 */
// eslint-disable-next-line import/prefer-default-export
export const processCashInvestableAssetsQuestion = question => {
  if (!question?.answer) return null;

  const { investment, percentage } = question.answer;

  // Format cash and investable assets answer
  const cashInvestableAnswer = `Cash and investable assets: ${
    investment ? formatMoney(investment) : '-'
  }`;

  // Format estimated invested answer
  const estimatedInvestedAnswer = `Estimated invested: ${
    percentage
      ? `${formatPercentage(percentage, 100)} (${formatMoney(investment * percentage)})`
      : '-'
  }`;

  return {
    cashInvestableAnswer,
    estimatedInvestedAnswer
  };
};
