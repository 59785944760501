import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { processRangeQuestion } from '../utils';
import ReportQuestion, { ReportQuestionSkeleton } from '../../base/pdf';
import { QuestionPropTypes } from '../../props';

const RangeQuestion = ({ question }) => {
  const processedData = processRangeQuestion(question);
  if (!processedData) return <ReportQuestionSkeleton question={question} />;

  const { value } = processedData;

  return (
    <ReportQuestion question={question}>
      <Text>{value}</Text>
    </ReportQuestion>
  );
};

RangeQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default RangeQuestion;
