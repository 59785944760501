import _ from 'lodash';
import { getTolerableLossAnswer } from '../utils';

/**
 * Formats the tolerable loss question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
export const formatTolerableLossCSVQuestion = question => {
  if (_.isNil(question?.answer)) return null;

  const formattedAnswer = getTolerableLossAnswer(question);

  return {
    number: question.question_number,
    question: question.question,
    answer_1: formattedAnswer
  };
};
