import { processAnnualTaxableIncomeQuestion } from '../utils';

/**
 * Formats the Annual Taxable Income Question for CSV output
 * @param {Object} question - The question object
 * @returns {Object|null} - The formatted question or null if invalid
 */
export const formatAnnualTaxableIncomeQuestion = question => {
  const processedData = processAnnualTaxableIncomeQuestion(question);
  if (!processedData) return null;

  const { questionText, incomeAnswer, contributionAnswer } = processedData;

  return {
    number: question.question_number,
    question: questionText,
    answer_1: incomeAnswer,
    answer_2: contributionAnswer
  };
};
