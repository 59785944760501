import { processRangeQuestion } from '../utils';

/**
 * Formats a range question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
export const formatRangeQuestion = question => {
  const processedData = processRangeQuestion(question);
  if (!processedData) return null;

  const { value } = processedData;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: value
  };
};
