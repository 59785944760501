import PropTypes from 'prop-types';
import React from 'react';
import { processAnnualTaxableIncomeQuestion } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const AnnualTaxableIncomeReadOnlyQuestion = ({ question }) => {
  const processedData = processAnnualTaxableIncomeQuestion(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  const { questionText, incomeAnswer, contributionAnswer } = processedData;

  return (
    <ReadOnlyQuestion question={{ ...question, question: questionText }}>
      <p>{incomeAnswer}</p>
      <p>{contributionAnswer}</p>
    </ReadOnlyQuestion>
  );
};

AnnualTaxableIncomeReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default AnnualTaxableIncomeReadOnlyQuestion;
