import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import { formatAddressQuestion } from 'containers/risk-tolerance-questionnaire/question/address/csv';
import { formatAnnualTaxableIncomeQuestion } from 'containers/risk-tolerance-questionnaire/question/annual-taxable-income/csv';
import { formatBirthdayRetirementQuestion } from 'containers/risk-tolerance-questionnaire/question/birthday-retirement/csv';
import { formatCashInvestableAssetsQuestion } from 'containers/risk-tolerance-questionnaire/question/cash-investable-assets/csv';
import { formatChoicesCSVQuestion } from 'containers/risk-tolerance-questionnaire/question/choices/csv';
import { formatExpectedReturnQuestion } from 'containers/risk-tolerance-questionnaire/question/expected-return/csv';
import { formatGoalApproachQuestion } from 'containers/risk-tolerance-questionnaire/question/goal-approach/csv';
import { formatLossReactionQuestion } from 'containers/risk-tolerance-questionnaire/question/loss-reaction/csv';
import { formatNumericQuestion } from 'containers/risk-tolerance-questionnaire/question/numeric/csv';
import { formatPortfolioConcentrationQuestion } from 'containers/risk-tolerance-questionnaire/question/portfolio-concentration/csv';
import { formatRangeQuestion } from 'containers/risk-tolerance-questionnaire/question/range/csv';
import { formatSectionSeparatorQuestion } from 'containers/risk-tolerance-questionnaire/question/section-separator/csv';
import { formatSingleYearOutcomeCSV } from 'containers/risk-tolerance-questionnaire/question/single-year-outcome/csv';
import { formatTextQuestion } from 'containers/risk-tolerance-questionnaire/question/text/csv';
import { formatTolerableLossCSVQuestion } from 'containers/risk-tolerance-questionnaire/question/tolerable-loss/csv';
import { formatValueRequirementCSVQuestion } from 'containers/risk-tolerance-questionnaire/question/value-requirement/csv';
import { formatWithdrawalRequirementCSVQuestion } from 'containers/risk-tolerance-questionnaire/question/withdrawal-requirement/csv';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { interpolateQuestions } from 'utils/questionnaire';

const getComponentForQuestion = (type, flavor) => {
  if (type === qt.CUSTOM) {
    if (flavor === qf.ADDRESS) return formatAddressQuestion;
    if (flavor === qf.ANNUAL_TAXABLE_INCOME) return formatAnnualTaxableIncomeQuestion;
    if (flavor === qf.BIRTHDAY_RETIREMENT) return formatBirthdayRetirementQuestion;
    if (flavor === qf.CASH_INVESTABLE_ASSETS) return formatCashInvestableAssetsQuestion;
    if (flavor === qf.EXPECTED_RETURN) return formatExpectedReturnQuestion;
    if (flavor === qf.GOAL_APPROACH) return formatGoalApproachQuestion;
    if (flavor === qf.LOSS_REACTION) return formatLossReactionQuestion;
    if (flavor === qf.PORTFOLIO_CONCENTRATION) return formatPortfolioConcentrationQuestion;
    if (flavor === qf.SINGLE_YEAR_OUTCOME) return formatSingleYearOutcomeCSV;
    if (flavor === qf.TOLERABLE_LOSS) return formatTolerableLossCSVQuestion;
    if (flavor === qf.VALUE_REQUIREMENT) return formatValueRequirementCSVQuestion;
    if (flavor === qf.WITHDRAWAL_REQUIREMENT)
      return (question, intl) => formatWithdrawalRequirementCSVQuestion(question, intl);
  }
  if (type === qt.LEGACY) {
    if (flavor === qf.BIRTHDAY_RETIREMENT) return formatBirthdayRetirementQuestion;
    if (flavor === qf.EXPECTED_RETURN) return formatExpectedReturnQuestion;
    if (flavor === qf.TOLERABLE_LOSS) return formatTolerableLossCSVQuestion;
  }
  if (type === qt.CHOICE) return formatChoicesCSVQuestion;
  if (type === qt.NUMERIC) return formatNumericQuestion;
  if (type === qt.RANGE) return formatRangeQuestion;
  if (type === qt.SECTION) return formatSectionSeparatorQuestion;
  if (type === qt.TEXT) return formatTextQuestion;
  return null;
};

const renderQuestion = (question, intl) => {
  const formatQuestion = getComponentForQuestion(question.question_type, question.data?.flavor);

  // If a formatting function exists, call it with the question
  return formatQuestion ? formatQuestion(question, intl) : null;
};

const downloadCSV = (csvData, filename = 'questionnaire.csv') => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CSVQuestionnaireDownload = ({
  questions: rawQuestions,
  investor,
  lastQuestionnaire,
  intl
}) => {
  const answers = rawQuestions.reduce(
    (acum, q) => ({ ...acum, [q.slug || `question-${q.position}`]: q.answer }),
    {}
  );
  const questions = interpolateQuestions(rawQuestions, answers);

  const questionDataArray = questions
    .map(question => renderQuestion(question, intl))
    .filter(item => item !== null);

  const maxAnswerCount = questionDataArray.reduce((max, q) => {
    const answerKeys = Object.keys(q).filter(key => key.startsWith('answer_'));
    return Math.max(max, answerKeys.length);
  }, 0);

  // Ensure all entries have the same number of answer fields, filling in with empty strings
  questionDataArray.forEach(q => {
    for (let i = 1; i <= maxAnswerCount; i++) if (!q[`answer_${i}`]) q[`answer_${i}`] = ''; // Fill missing answer fields with an empty string
  });

  // Generate the `quotes` array dynamically based on detected keys
  const largestObject = questionDataArray.reduce(
    (max, current) => (Object.keys(current).length > Object.keys(max).length ? current : max),
    {}
  );
  const quotesArray = Object.keys(largestObject).map(key => key !== 'number');

  const csvData = Papa.unparse(questionDataArray, { quotes: quotesArray });

  downloadCSV(
    csvData,
    `${lastQuestionnaire.template.name} for ${investor.user.full_name || investor.user.name}.csv`
  );
};

CSVQuestionnaireDownload.defaultProps = {
  title: 'Form Answers'
};

CSVQuestionnaireDownload.propTypes = {
  questions: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default CSVQuestionnaireDownload;
