/**
 * Formats a section separator question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no question is provided.
 */
export const formatSectionSeparatorQuestion = question => {
  if (!question) return null;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: '' // Section separators do not have an answer
  };
};
