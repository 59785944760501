import EditBoxText from 'components/advisor/utils/edit-box-text';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import DigitalSignature from './digital-signature';
import SimpleSignature from './simple-signature';
import './styles.scss';

const Signature = ({ initialValues, saveChanges: sourceSaveChanges, ...rest }) => {
  const {
    user: {
      advisor: {
        company: { digital_signature_enabled: digitalSignatureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  const initialSigners = initialValues?.signers || [];

  const [signers, setSigners] = useState(initialSigners);

  const canAddOrSaveSigners = signers.every(
    signer => !!signer.type && !!signer.id && !!signer.signatureType
  );

  const saveChanges = (propertyKey, sourceValues) => {
    const values = { ...sourceValues };
    if (digitalSignatureEnabled) if (!_.isEmpty(signers)) values.signers = signers;
    return sourceSaveChanges(propertyKey, values);
  };

  useEffect(() => {
    setSigners(initialSigners);
  }, [JSON.stringify(initialSigners)]);

  return (
    <EditBoxText {...rest} saveChanges={saveChanges} allowSave={canAddOrSaveSigners}>
      <div className="signature">
        {!digitalSignatureEnabled && <SimpleSignature {...rest} saveChanges={saveChanges} />}
        {digitalSignatureEnabled && (
          <DigitalSignature
            {...rest}
            canAddOrSaveSigners={canAddOrSaveSigners}
            setSigners={setSigners}
            signers={signers}
          />
        )}
      </div>
    </EditBoxText>
  );
};

Signature.propTypes = {
  initialValues: PropTypes.object.isRequired,
  investors: PropTypes.array.isRequired,
  saveChanges: PropTypes.func.isRequired
};

export default Signature;
