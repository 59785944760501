import config from 'app/config';
import cn from 'classnames';
import AccountTags from 'components/advisor/accounts/account-tags';
import { ACCOUNT_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import PrismRiskAnalysis from 'components/advisor/risk-analysis/prism-risk-analysis';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import RiskScoreBubble from 'components/advisor/utils/score-bubble/risk';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getScoreDataByScoreName } from 'providers/model';
import React, { useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { triggerAccountPrismScore } from 'utils/prism';
import './styles.scss';

const ToggableAccount = ({ account, investor, prismScoresInProgress, skipChartSaving }) => {
  const { accountProvider, investorProvider, prospectProvider } = useContext(AdvisorContext);

  const [expanded, setExpanded] = useState(false);

  const getPrismScore = accountId => {
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    triggerAccountPrismScore(accountId, accountProvider, () => [provider.getAccounts(investor.id)]);
  };

  const onChartReady = useCallback(
    (id, data) => {
      accountProvider.saveChartImage({ [id]: data });
    },
    [JSON.stringify(account)]
  );

  const { values: scores } = config.prism.scores;

  const hasPositions = !_.isEmpty(account.positions);
  const riskScores = account.prism_output?.custom?.risk_scores?.[0];
  const url = `/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
    investor.id
  }/account/${account.id}`;

  return (
    <div className="toggable-account">
      <div className="toggable-header" tabIndex="0" role="button" onKeyDown={() => {}}>
        <div className="toggable-header__score">
          <RiskScoreBubble
            element={account}
            inProgress={prismScoresInProgress.includes(account.id)}
            onGenerate={getPrismScore}
          />
        </div>
        <div className="toggable-header__name">
          <Link to={url}>{account.display_name}</Link>
          <AccountTags account={account} />
        </div>
        <div className="toggable-header__report">
          {account.prism_score_summary && (
            <Link to={`${url}/prism`} className="btn btn-link btn-risk-report">
              Risk Report
            </Link>
          )}
        </div>
      </div>

      {/* its hidden for the PDF  */}
      {hasPositions && (
        <div className={cn('toggable-content', { expanded })}>
          <PositionsAnalysis
            id={`${PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}-${account.id}`}
            hiddenVal={false}
            onChartReady={onChartReady}
            portfolio={account}
          />
        </div>
      )}

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      {account.prism_score_summary && (
        <div className="hide-from-report">
          <PrismRiskAnalysis
            portfolio={account}
            prismSummary={{
              concentrated: getScoreDataByScoreName(riskScores, scores.CONCENTRATED_SCORE),
              correlation: getScoreDataByScoreName(riskScores, scores.CORRELATION_SCORE),
              tail: getScoreDataByScoreName(riskScores, scores.TAIL_SCORE),
              volatility: getScoreDataByScoreName(riskScores, scores.VOLATILITY_SCORE)
            }}
            skipChartSaving={skipChartSaving}
            targetSummary={account?.target_score_summary}
            type={ACCOUNT_TARGET_TYPE}
          />
        </div>
      )}

      {/**
       * Used only to take the image of the chart that is needed for @react-pdf/renderer
       *
       * Because the `hide-from-report` class container is positioned outside the viewport,
       * its width and height are 0 by default, making it impossible for @amcharts/amcharts5 to
       * calculate the size of the container and preventing the chart from being rendered.
       * For this reason, the width and height dimensions are specified manually.
       */}
      {hasPositions && (
        <div className="hide-from-report" style={{ width: 1000, height: 200 }}>
          <SecurityTypeConcentration
            id={`${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${account.id}`}
            positions={account.positions}
            onChartReady={onChartReady}
            totalAssets={account.value}
          />
        </div>
      )}
    </div>
  );
};

ToggableAccount.propTypes = {
  account: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired,
  skipChartSaving: PropTypes.bool
};

ToggableAccount.defaultProps = {
  skipChartSaving: false
};

export default connect(state => ({
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state)
}))(ToggableAccount);
