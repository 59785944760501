import { OTHER_VALUE } from './constants';

/**
 * Processes the Goal and Approach question data for reusability.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Processed data with formatted goal and approach selections or null if invalid.
 */
export const processGoalApproachQuestion = question => {
  if (!question?.answer) return null;

  // Helper function to handle 'Other' value formatting
  const withOtherValue = answer => choice =>
    choice.value === OTHER_VALUE ? `${choice.value} (${answer})` : choice.value;

  // Format goals and approaches as strings
  const goalSelection = question.data?.goals
    .filter(choice => question.answer.goal.includes(choice.value))
    .map(withOtherValue(question.answer.other_goal))
    .join(', ');

  const approachSelection = question.data?.approaches
    .filter(choice => question.answer.approach.includes(choice.value))
    .map(withOtherValue(question.answer.other_approach))
    .join(', ');

  return {
    goalSelection,
    approachSelection
  };
};
