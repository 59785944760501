import { processChoicesQuestion } from '../utils';

/**
 * Formats the choices question for CSV export.
 * @param {Object} question - The question object containing data and answers.
 * @returns {Object|null} - The formatted question for CSV or null if no answer.
 */
export const formatChoicesCSVQuestion = question => {
  const processedData = processChoicesQuestion(question);
  if (!processedData) return null;

  const { selection } = processedData;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: selection
  };
};
