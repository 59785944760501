import _ from 'lodash';
import { ageCalculator } from 'utils/questionnaire';

/**
 * Processes birthday and retirement data for reusability.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Processed data with age and retirement details or null if invalid.
 */
export const processBirthdayRetirementData = question => {
  if (_.isNil(question?.answer)) return null;

  const { employment, birthday, retirement } = question.answer;

  const age = ageCalculator(birthday);
  const retirementAge = Number(retirement) || 0;
  const retireIn = retirementAge - age;

  const answer1 = `${age} years old`;
  const answer2 = employment
    ? employment[0]
    : retireIn > 0
      ? `I'm planning to retire in ${retireIn} years`
      : 'I am retired';

  return { answer1, answer2 };
};
