import PropTypes from 'prop-types';
import React from 'react';
import { processBirthdayRetirementData } from '../utils';
import ReadOnlyQuestion, { ReadOnlyQuestionSkeleton } from '../../base/read-only';
import { QuestionPropTypes } from '../../props';

const BirthdayRetirementReadOnlyQuestion = ({ question }) => {
  const processedData = processBirthdayRetirementData(question);
  if (!processedData) return <ReadOnlyQuestionSkeleton question={question} />;

  return (
    <ReadOnlyQuestion question={question}>
      <p>{processedData.answer1}</p>
      <p>{processedData.answer2}</p>
    </ReadOnlyQuestion>
  );
};

BirthdayRetirementReadOnlyQuestion.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired
};

export default BirthdayRetirementReadOnlyQuestion;
