/* global DISPLAY_DATE_FORMAT */
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import { AdvisorPropTypes, UserPropTypes } from 'app/types';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from '../../constants';
import StratifiBrand from '../brand/stratifi';
import { DEFAULT_PRIMARY_COLOR, getImageForReport } from '../utils';
import styles from './styles';
import { BaseCoverDataPropTypes } from './types';

const getAddress = address => {
  if (!_.isEmpty(address)) {
    const { address1, city, state, country, postcode } = address;
    return [address1, city, state, country, postcode]
      .filter(a => !!a)
      .join(', ')
      .replace(/,\s*$/, '');
  }
  return '';
};

export const AdvisorBaseCover = ({ advisor, ...rest }) => (
  <BaseCover {...rest} user={advisor.user} advisor={advisor} />
);

AdvisorBaseCover.propTypes = {
  advisor: PropTypes.shape(AdvisorPropTypes).isRequired
};

const BaseCover = ({
  advisor,
  data,
  orientation,
  size,
  subtitle,
  title,
  user,
  withCustomBranding
}) => {
  const preparedFor = data.prepared_for || subtitle;
  const preparedBy = data.prepared_by || `${user.first_name} ${user.last_name}`;

  const address = data.address || advisor.address || advisor.company.address;
  const company = data.company_name || advisor.company_name || advisor.company.name;
  const email = data.email || user.email || advisor.company.email;
  const logo = advisor.logo || data.logo || advisor.company.logo;
  const phone = data.phone || advisor.phone || advisor.company.phone;
  const advisorTitle = data.title ?? advisor.title;
  const website = data.website || advisor.website || advisor.company.website;
  const advisorPrimaryBrandColor = advisor.brand_primary_color;

  const brandPrimaryColor = withCustomBranding
    ? advisorPrimaryBrandColor || advisor.company?.brand_primary_color || DEFAULT_PRIMARY_COLOR
    : DEFAULT_PRIMARY_COLOR;

  return (
    <Page size={size} orientation={orientation}>
      <View style={[styles.top, { borderLeftColor: brandPrimaryColor }]}>
        {!logo ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link src="https://www.stratifi.com/">
            <StratifiBrand width={300} style={styles.brand} />
          </Link>
        ) : (
          <Image src={getImageForReport(logo)} style={styles.brand} />
        )}
        <Text style={styles.title}>{title}</Text>
        {subtitle && (
          <View style={styles.subtitleContainer}>
            <Text style={styles.preparedFor}>Prepared for </Text>
            <Text style={styles.subtitle}>{preparedFor}</Text>
          </View>
        )}
      </View>

      <View style={styles.center}>
        <View style={styles.advisorContainer}>
          <Text style={styles.preparedBy}>Prepared by</Text>
          <Text style={styles.preparedByContent}>{preparedBy}</Text>
          {!!advisorTitle && <Text style={styles.advisorTitle}>{advisorTitle}</Text>}
          {!data.prepared_by_company && <Text style={styles.advisorCompany}>{company}</Text>}
        </View>
        <View style={styles.companyContainer}>
          <Text>
            {phone && `Phone: ${formatPhoneNumber(phone)}`}
            {phone && email && ' • '}
            {email && `Email: ${email}`}
          </Text>
          {!!website && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link src={website} style={styles.website}>
              <Text>{website}</Text>
            </Link>
          )}
          {address && <Text>{getAddress(address)}</Text>}
        </View>
      </View>

      <View style={[styles.bottom, { borderRightColor: brandPrimaryColor }]}>
        <View style={styles.poweredBy}>
          <Text style={{ marginRight: 5 }}>Powered by</Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link src="https://www.stratifi.com/">
            <StratifiBrand width={85} />
          </Link>
        </View>
      </View>
    </Page>
  );
};

BaseCover.propTypes = {
  advisor: PropTypes.shape(AdvisorPropTypes).isRequired,
  data: PropTypes.shape(BaseCoverDataPropTypes),
  orientation: PropTypes.string,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  user: PropTypes.shape(UserPropTypes).isRequired,
  withCustomBranding: PropTypes.bool
};

BaseCover.defaultProps = {
  data: {},
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: undefined,
  withCustomBranding: false
};

export default BaseCover;
