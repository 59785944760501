import _ from 'lodash';
import { DEFAULT_AMOUNT, formatPotentialGainLossText } from '../utils';

/**
 * Formats the Single Year Outcome question for CSV.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted question data or null if the answer is invalid.
 */
export const formatSingleYearOutcomeCSV = question => {
  if (!question || _.isNil(question.answer)) return null;

  const defaultAmount = question.data?.default_amount || DEFAULT_AMOUNT;
  const selectedOption = question.data.labels.find(label => label.value === question.answer.value);

  if (!selectedOption) return null;

  const answerText = formatPotentialGainLossText({
    defaultAmount,
    option: selectedOption
  });

  return {
    number: question.question_number,
    question: question.question,
    answer_1: answerText
  };
};
