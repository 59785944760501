import _ from 'lodash';
import { processBirthdayRetirementData } from '../utils';

/**
 * Formats the birthday and retirement question into CSV-compatible data.
 * @param {Object} question - The question object containing answers and metadata.
 * @returns {Object|null} - Formatted CSV data or null if the answer is invalid.
 */
export const formatBirthdayRetirementQuestion = question => {
  const processedData = processBirthdayRetirementData(question);
  if (!processedData) return null;

  return {
    number: question.question_number,
    question: question.question,
    answer_1: processedData.answer1,
    answer_2: processedData.answer2
  };
};
