import React from 'react';
import PropTypes from 'prop-types';
import NewTag from 'containers/advisor/new-tag';

import './styles.scss';

export const CardOverview = ({ title, children, newTag, className }) => (
  <div className={`card-overview ${className}`}>
    <div className="card-header">
      {title} {newTag && <NewTag />}
    </div>
    <div className="card-body">{children}</div>
  </div>
);

CardOverview.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  newTag: PropTypes.bool
};

CardOverview.defaultProps = {
  className: '',
  newTag: false
};

export default CardOverview;
